import React, { createContext, useEffect, useState } from 'react'

import {
  AccountInfo,
  EventType,
  InteractionType,
  IPublicClientApplication,
  PopupRequest,
  PublicClientApplication,
} from '@azure/msal-browser'
import { useMsal, useMsalAuthentication } from '@azure/msal-react'
import { useQueryClient } from '@tanstack/react-query'
import { config } from 'config'

export enum AuthState {
  INITIATING = 'initiating',
  LOGGED_IN = 'logged_in',
  LOGGED_OUT = 'logged_out',
}

export interface IAuthProvider {
  authState: AuthState
  activeAccount: AccountInfo | null
  instance: IPublicClientApplication
  logIn: () => void
  logOut: () => void
  getToken: () => Promise<string>
}

const AuthContext = createContext({} as IAuthProvider)

interface AuthProps {
  children?: React.ReactNode
}
const AuthProvider: React.FunctionComponent<AuthProps> = ({ children }) => {
  const { error, result } = useMsalAuthentication(InteractionType.Silent, loginRequest)
  const [authState, setAuthState] = useState(AuthState.INITIATING)
  const { instance } = useMsal()
  const queryClient = useQueryClient()
  const activeAccount = instance.getActiveAccount()

  useEffect(() => {
    if (error) {
      setAuthState(AuthState.LOGGED_OUT)
    }
    if (result) {
      setAuthState(AuthState.LOGGED_IN)
    }
  }, [error, result])

  const logOut = () => {
    instance
      .logoutPopup({ account: activeAccount })
      .then(() => {
        queryClient.clear()
      })
      .catch((e) => {
        console.error(e)
      })
    setAuthState(AuthState.LOGGED_OUT)
  }

  const logIn = () => {
    instance
      .loginPopup(loginRequest)
      .then(() => setAuthState(AuthState.LOGGED_IN))
      .catch((e: any) => {
        console.error(e)
      })
  }

  const getToken = async () => {
    if (!activeAccount) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.',
      )
    }

    const request = {
      ...loginRequest,
      account: activeAccount,
    }
    const response = await instance.acquireTokenSilent(request)

    return response.accessToken
  }

  const authContextValue = {
    authState,
    instance,
    activeAccount,
    logIn,
    logOut,
    getToken,
  }
  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }

export const msalConfig = {
  auth: {
    clientId: config.msal.clientId,
    authority: config.msal.authority,
    redirectUri: config.msal.redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
}

export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
}

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.addEventCallback((event: any) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account)
  }
})
