import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Policy } from 'types'

export const usePolicyTemplates = () => {
  const { axiosClient } = useAxios()

  return useQuery(['policyTemplates'], async () => {
    const { data } = await axiosClient.get<Policy[]>(config.policyTemplates)

    return data
  })
}
