import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Button, Toolbar } from 'components'
import { UsersTable } from 'components/tables'
import { useFacilitiesAll } from 'hooks/api/management'
import { useUsers } from 'hooks/api/users'

const Admin: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { data: allUsers } = useUsers()
  const { data: allFacilities } = useFacilitiesAll()

  return (
    <>
      <TopBar>
        <h1>{t('admin.header')}</h1>
        <Toolbar>
          <Button link='/admin/user/new' icon='add' />
        </Toolbar>
      </TopBar>
      {Boolean(allUsers?.length) && Boolean(allFacilities) && (
        <UsersTable users={allUsers} facilities={allFacilities} />
      )}
    </>
  )
}

export default Admin

const TopBar = styled.div`
  display: flex;
  margin-bottom: 38px;
  align-items: center;
`
