import React from 'react'
import { createRoot } from 'react-dom/client'

import { MsalProvider } from '@azure/msal-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { AppDataProvider } from 'context'
import { Router } from 'routing'
import { Theme } from 'styles'

import './i18n'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'

import { AuthProvider, msalInstance } from 'context/AuthProvider'

import { reactPlugin } from './AppInsights'

const container = document.getElementById('root')
const root = createRoot(container || null)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const AppWrapper: React.FC = () => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={msalInstance}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <AppDataProvider>
              <Theme>
                <Router />
              </Theme>
            </AppDataProvider>
          </AuthProvider>
        </QueryClientProvider>
      </MsalProvider>
    </AppInsightsContext.Provider>
  )
}

root.render(<AppWrapper />)
