import React, { createContext, useContext } from 'react'

import { ThemeMode, Tool, View } from 'types'

interface Props {
  children: React.ReactNode
}

type Action =
  | { type: 'setGroupBy'; payload: ('type' | 'status') | null }
  | { type: 'setSortOrder'; payload: string }
  | { type: 'setTheme'; payload: ThemeMode }
  | { type: 'setTool'; payload: Tool }
  | { type: 'setView'; payload: View }

type State = {
  groupBy: ('type' | 'status') | null
  sortOrder: string
  theme: ThemeMode
  tool: Tool
  view: View
  dispatch: React.Dispatch<Action>
}

const initialState: State = {
  groupBy: null,
  sortOrder: 'asc',
  theme: 'dark',
  tool: null,
  view: 'grid',
  dispatch: () => null,
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setGroupBy':
      localStorage.setItem('groupBy', action.payload)
      return { ...state, groupBy: action.payload }
    case 'setSortOrder':
      localStorage.setItem('sortOrder', action.payload)
      return { ...state, sortOrder: action.payload }
    case 'setTheme':
      localStorage.setItem('theme', action.payload)
      return { ...state, theme: action.payload }
    case 'setTool':
      return { ...state, tool: action.payload }
    case 'setView':
      localStorage.setItem('view', action.payload)
      return { ...state, view: action.payload }
    default:
      return state
  }
}

const getLocalState = (): State => {
  const groupBy = localStorage.getItem('groupBy')
  const sortOrder = localStorage.getItem('sortOrder')
  const theme = localStorage.getItem('theme')
  const view = localStorage.getItem('view')

  return {
    groupBy: groupBy ? (groupBy as 'type' | 'status') : null,
    sortOrder: sortOrder ? (sortOrder as 'asc' | 'desc') : 'asc',
    theme: theme ? (theme as ThemeMode) : 'light',
    tool: null,
    view: view ? (view as View) : 'grid',
    dispatch: () => null,
  }
}

const AppDataContext = createContext<State>(undefined)

export const AppDataProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState, getLocalState)

  return (
    <AppDataContext.Provider value={{ ...state, dispatch }}>{children}</AppDataContext.Provider>
  )
}

export const useAppData = () => {
  const context = useContext(AppDataContext)

  if (context === undefined) {
    throw new Error('useAppData must be used within an AppDataProvider')
  }

  return context
}
