import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Button, ClusterCard } from 'components'
import { CanEdit } from 'components/util/CanEdit'
import { useClusters } from 'hooks/api/clusters'
import { useFacility, useFacilityImg } from 'hooks/api/facilities'
import { Module } from 'types'

interface Props {
  facilityId?: string
  isActive?: string
}

export const FacilityClustersTab: React.FC<Props> = ({ facilityId }) => {
  const { t } = useTranslation()
  const { data: facility } = useFacility(facilityId)
  const { data: clusters } = useClusters(facilityId)

  const { data: image } = useFacilityImg(facilityId, {
    enabled: !!facility?.img,
  })

  return (
    <>
      <Header>
        <h3>{t('clusters.clusters')}</h3>
        <CanEdit module={Module.PROPERTY}>
          <Button link={`/map/facilities/${facilityId}/clusters/new`} icon='add' />
        </CanEdit>
      </Header>

      {!clusters?.length ? (
        <CanEdit module={Module.PROPERTY}>
          <p>
            {t('clusters.noClusters')} &nbsp;
            <a href={`/map/facilities/${facilityId}/clusters/new`}>{t('common.create')}</a>
          </p>
        </CanEdit>
      ) : (
        <ClusterWrapper>
          {clusters.map((cluster, i) => (
            <ClusterCard key={cluster.id} cluster={cluster} image={image} number={i} />
          ))}
        </ClusterWrapper>
      )}
    </>
  )
}

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const ClusterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
`
