import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'

export const useFacilityDelete = (id: string) => {
  const { axiosClient } = useAxios()

  return useMutation(
    async () => {
      const { data } = await axiosClient.delete(config.facility, { params: { facilityId: id } })

      return data
    },
    {
      onSuccess: () => {
        window.location.href = '/map/facilities'
      },
    },
  )
}
