export enum Categories {
  AGRICULTURE = 'Agriculture',
  ART = 'Art',
  EDUCATION = 'Education',
  HEALTHCARE = 'HealthCare',
  HIGH_RISK = 'HighRisk',
  MARITIME = 'MariTime',
  OFFICES = 'Offices',
  OPEN_AIR = 'OpenAir',
  PARKING = 'Parking',
  PRODUCTION = 'Production',
  RESEARCH = 'Research',
  RESIDENTIAL = 'Residential',
  RETAIL = 'Retail',
  SPORT = 'Sport',
  TRANSPORT = 'Transport',
  WAREHOUSE = 'Warehouse',
  NONE = 'None',
}

export enum ClusterDiscipline {
  ADDITIONAL = 'Additional',
  ASSEMBLY = 'Assembly',
  EDUCATION = 'Education',
  HEALTHCARE = 'Healthcare',
  LODGING = 'Lodging',
  NONE = 'None',
  OFFICE = 'Office',
  OTHER = 'Other',
  PRISON = 'Prison',
  RESIDENTIAL = 'Residential',
  SPORTS = 'Sports',
  STORE = 'Store',
}

export enum MaintenanceLevel {
  BASIC = 'Basic',
  MINIMAL = 'Minimal',
  NONE = 'None',
  PLUS = 'Plus',
}

export enum WorkingDaysShort {
  MON_FRI = 'Mon-Fri',
  SAT_SUN = 'Sat-Sun',
}

export enum WorkingDaysLong {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATERDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export enum RequestType {
  COMPLAINT = 'Complaint',
  MALFUNTION = 'Malfunction',
  NONE = 'None',
  REQUEST = 'Request',
}

type ActionStatus = 'Scheduled' | 'Ongoing' | 'Completed' | 'Overdue'
type EmissionCategory = 'Gas' | 'Power' | 'Water' | 'Operation' | 'Service' | 'Waste'
type PriorityLevel = 'P1' | 'P2' | 'P3' | 'P4' | 'P5' | 'P6' | 'P7' | 'P8' | 'P9' | 'P10'

export type ThemeMode = 'light' | 'dark'
export type ThemeType = {
  mode: ThemeMode
  tool: Tool
}
export type Tool = 'admin' | 'settings' | 'costs' | 'map' | 'fix' | 'docs' | 'care' | null
export type View = 'grid' | 'list'

export interface Action {
  id?: string
  title: string
  description: string
  emissionCategory: EmissionCategory
  subcategory: string
  uniqueIdentifier: string
  status: ActionStatus
  cost: number
  kwhSavedYearly: number
  startDate: Date
  endDate: Date
  contactId: string
  facilityId: string
}

export interface Address {
  street?: string
  buildingNumber?: number
  extension?: string
  postCode?: string
  city?: string
  municipality?: string
  province?: string
  latitude?: number
  longitude?: number
  latFormatted?: string
  lonFormatted?: string
}

export interface Cluster {
  id?: string
  name?: string
  hasOpenHours?: boolean
  openHours?: {
    day: string
    from?: string
    to?: string
    isOpen: boolean
  }[]
  maintenanceLevel?: MaintenanceLevel
  preventiveMaintenance?: boolean
  inspections?: boolean
  malfunctions?: boolean
  policyId?: string
  facilityId?: string
  disciplines?: ClusterDiscipline[]
  contacts?: Contact[]
  policy?: Policy
  updatedUtc?: string
  createdUtc?: string
}

export interface Contact {
  id?: string
  firstName?: string
  lastName?: string
  title?: string
  email?: string
  phone?: string
  isOwner?: boolean
  facilityId?: string
}

export interface Document {
  id?: string
  title: string
  status: string
  type: string
  file?: string
  tentantId?: string
  facilityId?: string
  createdUtc?: string
  updatedUtc?: string
}

export interface User {
  email: string
  BIAccess: boolean
  type: UserType
  permissions: Permission[]
}

export interface UserExtended extends User {
  id: string
  facilityIds: string[]
  tenantId: string
  createdUtc: string
  updatedUtc: string
}

export enum UserType {
  CLIENT = 'Client',
  OWNER = 'Owner',
}

export interface Permission {
  module: Module
  roles: Role[]
}

export enum Module {
  PROPERTY = 'Property',
  FINANCIAL = 'Financial',
  SERVICE = 'Service',
  MYUNICA = 'MyUnica',
  SUSTAINABILITY = 'Sustainability',
  BUILDINGINSIGHTS = 'BuildingInsights',
}

export enum Role {
  READER = 'Reader',
  CONTRIBUTOR = 'Contributor',
  ADMIN = 'Admin',
}

export interface Facility {
  id?: string
  tenantId?: string
  name?: string
  description?: string
  categories?: string[]
  suggestedCategory?: string
  isMonument?: boolean
  isNtaApproved?: boolean
  location?: Address
  details?: {
    year?: number
    grossArea?: number
    usefulArea?: number
    rentArea?: number
    formFactor?: number
    list?: (string | number)[]
  }
  energyLabel?: string
  fossilConsumption?: number
  img?: string
  imageBlob?: string
  updatedUtc?: string
  createdUtc?: string
  clusters?: Cluster[]
  requests?: Request[]
  documents?: Document[]
}

export interface Policy {
  id?: string
  name: string
  priority: PriorityLevel
  facilityId?: string
  clusterId?: string
  policyTemplateId?: string
  tenantId?: string
  image?: string
  createdUtc?: string
  updatedUtc?: string
  goals: {
    energy: {
      health: number
      usability: number
      environment: number
      safety: number
      society: number
    }
    environment: {
      health: number
      usability: number
      energy: number
      safety: number
      society: number
    }
    health: {
      usability: number
      energy: number
      environment: number
      safety: number
      society: number
    }
    safety: {
      health: number
      usability: number
      energy: number
      environment: number
      society: number
    }
    society: {
      health: number
      usability: number
      energy: number
      environment: number
      safety: number
    }
    usability: {
      health: number
      energy: number
      environment: number
      safety: number
      society: number
    }
  }
  subgoals: {
    energy: {
      energyEfficiency: {
        emission: number
      }
      emission: {
        energyEfficiency: number
      }
    }
    environment: {
      culturalHistoricalValue: {
        imageAspects: number
        knowledgeLoss: number
      }
      imageAspects: {
        culturalHistoricalValue: number
        knowledgeLoss: number
      }
      knowledgeLoss: {
        culturalHistoricalValue: number
        imageAspects: number
      }
    }
    health: {
      comfortAndCosiness: {
        wellbeing: number
      }
      wellbeing: {
        comfortAndCosiness: number
      }
    }
    safety: {
      limitCasualties: {
        limitDamageThirdParties: number
      }
      limitDamageThirdParties: {
        limitCasualties: number
      }
    }
    society: {
      sustainability: {
        limitDamageSurrounding: number
      }
      limitDamageSurrounding: {
        sustainability: number
      }
    }
    usability: {
      limitDamageUserProcess: {
        limitDamageOwnerFinancially: number
        safetyInternalExternal: number
      }
      limitDamageOwnerFinancially: {
        limitDamageUserProcess: number
        safetyInternalExternal: number
      }
      safetyInternalExternal: {
        limitDamageUserProcess: number
        limitDamageOwnerFinancially: number
      }
    }
  }
}

export interface RequestFile extends File {
  id?: string
  fileName?: string
  uploadedFileName?: string
}

export interface Request {
  id?: string
  tenantId?: string
  status?: string
  type: RequestType
  isEntireFacility: boolean
  locationDescription?: string
  details?: string
  contacts?: Contact[]
  contactIds?: string[]
  files?: RequestFile[]
  facilityId?: string
  updatedUtc?: string
  createdUtc?: string
}
