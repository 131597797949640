import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import styled from 'styled-components'

import { BreadcrumbNav, Button, DropdownItem, DropdownMenu, type Crumb } from 'components'
import { CanEdit } from 'components/util/CanEdit'
import { useOutsideClick } from 'hooks'
import { useClusterDelete, useClusters } from 'hooks/api/clusters'
import { useFacility, useFacilityImg } from 'hooks/api/facilities'
import { PriorityLevels, usePolicy, usePolicyImg, usePolicyTemplate } from 'hooks/api/policies'
import { Module } from 'types'

export const ClusterDetail: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { clusterId, facilityId } = useParams()
  const [showDropdown, setShowDropdown] = React.useState(false)
  const ref = useOutsideClick(() => setShowDropdown(false))
  const { data: facility } = useFacility(facilityId)
  const { data: clusters } = useClusters(facilityId)

  const cluster = clusters?.find((cluster) => cluster.id === clusterId) || null

  const { data: policyCustom } = usePolicy(cluster?.facilityId, cluster?.id, {
    enabled: !!cluster?.policyId,
  })
  const { data: policyTemplate } = usePolicyTemplate(policyCustom?.policyTemplateId, {
    enabled: !!cluster?.policyId,
  })

  const policy = policyCustom ? { ...policyTemplate, ...policyCustom } : null

  const { data: facilityImage } = useFacilityImg(facility.id, { enabled: !!facility?.id })

  const { data: policyImage } = usePolicyImg(policy?.policyTemplateId, {
    enabled: !!cluster?.policyId,
  })

  const priorityLevel = PriorityLevels.find((level) => level.value === policy?.priority)

  const { mutate: deleteCluster } = useClusterDelete(facilityId, clusterId)

  const editCluster = () => {
    navigate(`/map/facilities/${facilityId}/clusters/${clusterId}/edit`)
  }

  const crumbs: Crumb[] = cluster?.policyId
    ? [
        {
          title: facility?.name,
          image: facilityImage || null,
          link: `/map/facilities/${facilityId}`,
          detailList: [facility?.energyLabel, facility?.categories[0]],
        },
        {
          title: cluster?.disciplines[0],
          image: policyImage || null,
          imageShape: 'circle',
          link: `/map/policies/${policy?.id}`,
          detailList: ['policy', (Math.random() * (10 - 6) + 6).toFixed(1)],
        },
      ]
    : [
        {
          title: facility?.name,
          image: facilityImage || null,
          link: `/map/facilities/${facilityId}`,
          detailList: [facility?.energyLabel, facility?.categories[0]],
        },
      ]

  return (
    <>
      <BreadcrumbNav crumbs={crumbs} size='large' />
      <TopBar>
        {!!facility?.img && (
          <ImageWrapper>
            {facilityImage && <img src={facilityImage} />}
            <Avatar>{policyImage && <img src={policyImage} />}</Avatar>
          </ImageWrapper>
        )}
        <Information>
          <h1>{cluster?.name}</h1>
          <Category>
            {t('clusters.clusterIn')} {facility?.name}
          </Category>
          <Specifications>
            {policy ? (
              <Spec>
                <Value>{policy.name}</Value>
                <Label>policy</Label>
              </Spec>
            ) : (
              <CanEdit module={Module.PROPERTY}>
                <Spec>
                  <a href={'/map/policies/new'}>{t('clusters.createPolicy')}</a>
                  <Label>{t('clusters.noPolicy')}</Label>
                </Spec>
              </CanEdit>
            )}
            <Spec>
              <Value>{cluster?.createdUtc?.substring(0, 10).split('-').reverse().join('-')}</Value>
              <Label>{t('clusters.created')}</Label>
            </Spec>
          </Specifications>
        </Information>

        <CanEdit module={Module.PROPERTY}>
          <Toolbar>
            <Button link={`/map/facilities/${facility?.id}/clusters/new`} icon='add' />
            <DropdownMenu innerRef={ref} show={showDropdown} setShow={setShowDropdown}>
              <DropdownItem name={t('common.edit')} action={editCluster} />
              <DropdownItem
                name={t('common.delete')}
                style='danger'
                action={() => deleteCluster()}
              />
            </DropdownMenu>
          </Toolbar>
        </CanEdit>
      </TopBar>
      <FullWidthContainer>
        <Content>
          <Sidebar>
            {cluster?.openHours.length > 0 && (
              <>
                <h3>{t('clusters.workingHours')}</h3>
                <WorkingHoursWrapper>
                  {cluster?.openHours.map((openHour, i) => (
                    <OpenHour key={i}>
                      <Day>{openHour.day}</Day>
                      <Hours>
                        {openHour.isOpen ? `${openHour.from} \u2014 ${openHour.to}` : 'Closed'}
                      </Hours>
                    </OpenHour>
                  ))}
                </WorkingHoursWrapper>
              </>
            )}
            {policy && priorityLevel && (
              <>
                <h3>{`Priority: ${priorityLevel.value} \u2014 Involved`}</h3>
                <DetailsWrapper>
                  {Object.keys(priorityLevel.details).map((key, i) => (
                    <Detail key={i}>
                      <span>
                        {priorityLevel.details[key as keyof typeof priorityLevel.details]}
                      </span>
                      <span>{key}</span>
                    </Detail>
                  ))}
                </DetailsWrapper>
              </>
            )}
          </Sidebar>
        </Content>
      </FullWidthContainer>
    </>
  )
}

const TopBar = styled.div`
  display: flex;
  margin: 50px 0 38px 0;
  margin-bottom: 38px;
  justify-content: start;
`

const Toolbar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
  align-items: baseline !important;
`

const Information = styled.div``

const Category = styled.p`
  margin: 10px 0 0 0;
`

const Specifications = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 40px;
`

const Spec = styled.div`
  margin-right: 40px;

  a {
    font-size: 24px;
  }
`

const Value = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 300;
  font-stretch: condensed;
`

const Label = styled.span`
  display: block;
  font-size: 12px;
`
const ImageWrapper = styled.div`
  position: relative;
  width: 180px;
  height: 180px;
  margin-right: 40px;
  backdrop-filter: blur(3px);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Avatar = styled.div`
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 85px;
  height: 85px;
  background-color: ${({ theme }) => theme.colors.card.avatar};
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
    object-fit: cover;
  }
`

const FullWidthContainer = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  background-color: ${({ theme }) => theme.colors.body.backgroundAlt};

  &.active {
    display: block;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 60px 60px;
`

const Sidebar = styled.div`
  width: 425px;

  h3 {
    margin-bottom: 20px;
  }
`

const WorkingHoursWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
`

const OpenHour = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`

const Day = styled.span`
  width: 75px;
`

const Hours = styled.span`
  width: 150px;
  text-align: right;
`

const DetailsWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row wrap;
  justify-content: flex-start;
  font-stretch: condensed;

  h3 {
    flex: 1 0 100%;
  }
`

const Detail = styled.div`
  display: flex;
  position: relative;
  flex: 0 0 50%;
  flex-flow: column nowrap;
  margin-bottom: 25px;

  span {
    &:first-child {
      font-size: 24px;
    }

    &:last-child {
      font-size: 12px;
    }
  }
`
