import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import { DropdownItem, DropdownMenu } from 'components'
import { useAppData } from 'context'
import { useOutsideClick } from 'hooks'
import { useDocumentDownload } from 'hooks/api/documents'
import { cardBody, cardContainer, cardDetailItem, cardDetailList, cardHeader } from 'styles'
import { Document } from 'types'

interface Props {
  className?: string
  document: Document
  facilityName: string
  header?: string
  number?: number
  total?: number
}

interface DetailItem {
  label: string
  value: string | number
  className?: string
}

export const DocumentCard: React.FC<Props> = ({
  className,
  document,
  facilityName,
  header,
  number,
  total,
}) => {
  const navigate = useNavigate()
  const { view } = useAppData()
  const [showDropdown, setShowDropdown] = React.useState(false)

  const formatDate = (): string => {
    const date = new Date(document.createdUtc)
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    if (date.toDateString() === today.toDateString()) {
      return 'Today'
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday'
    } else {
      return date.toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric', year: '2-digit' })
    }
  }

  const handleOutsideClick = () => {
    setShowDropdown(false)
  }

  const detailItems: DetailItem[] =
    view === 'grid'
      ? [
          {
            label: 'status',
            value: document.status,
            className: document.status === 'New' ? 'highlight' : '',
          },
          { label: 'type', value: document.type },
        ]
      : [
          {
            label: 'status',
            value: document.status,
            className: document.status === 'New' ? 'highlight' : '',
          },
          { label: 'type', value: document.type },
          { label: 'date', value: formatDate() },
        ]

  const ref = useOutsideClick(handleOutsideClick)

  const { data: download } = useDocumentDownload(document)

  const editDocument = () => {
    navigate(`/docs/facilities/${document.facilityId}/documents/${document.id}/edit`)
  }

  return (
    <CardContainer
      className={`${className} ${view} ${header ? 'header' : ''}`}
      number={number}
      total={total}
    >
      <CardHeader>
        <TopText>{facilityName}</TopText>
        <DropdownMenu innerRef={ref} show={showDropdown} setShow={setShowDropdown}>
          <DropdownItem name='download' action={() => window.open(download)} />
          <DropdownItem name='email' action={() => false} />
          <DropdownItem name='edit tags' action={editDocument} />
          <DropdownItem name='remove' style='danger' action={() => false} />
        </DropdownMenu>
      </CardHeader>
      <CardBody>
        <h3>{header ? header : document.title}</h3>
        <DetailList>
          {detailItems &&
            detailItems
              .filter((detail) => detail.value !== '' && detail.value !== 'None')
              .map(({ value, className }, i) => (
                <DetailItem key={i} className={className}>
                  {value}
                </DetailItem>
              ))}
        </DetailList>
      </CardBody>
    </CardContainer>
  )
}

interface CardProps {
  number?: number
  total?: number
}

const CardContainer = styled.div<CardProps>`
  ${cardContainer}

  background-color: ${({ theme }) => theme.colors.card.backgroundAlt};
  flex-flow: row wrap;
  padding-top: 0;

  &:hover {
    cursor: default;
  }

  &.list {
    padding: 8px 32px;
    border-radius: 4px;
  }

  &.header {
    width: 550px;
  }

  &.status,
  &.type {
    margin: 0 0 0 60px;
  }
`

const CardHeader = styled.div`
  ${cardHeader}

  ${CardContainer}.list & {
    flex: 0 1 225px;
    margin-bottom: 0;

    .dropDown {
      position: absolute;
      right: 16px;
      z-index: 10;
    }
  }

  ${CardContainer}.header & {
    display: none;
  }
`

const CardBody = styled.div`
  ${cardBody}

  ${CardContainer}.list & {
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
  }
`

const TopText = styled.span`
  color: ${({ theme }) => theme.colors.card.subtle};
`

const DetailList = styled.ul`
  ${cardDetailList}

  ${CardContainer}.list & {
    flex-grow: 1;
    justify-content: flex-end;
    margin: 0;
  }

  ${CardContainer}.header & {
    display: none;
  }
`

const DetailItem = styled.li`
  ${cardDetailItem}

  ${CardContainer}.list & {
    min-width: 125px;

    &::after {
      display: none;
    }
  }
`
