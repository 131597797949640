import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import i18n from 'i18n'
import styled from 'styled-components'

import { ReactComponent as CostsIcon } from 'assets/costs-icon.svg'
import { ReactComponent as DocsIcon } from 'assets/docs-icon.svg'
import { ReactComponent as FixIcon } from 'assets/fix-icon.svg'
import { ReactComponent as MapIcon } from 'assets/map-icon.svg'
import { useAppData } from 'context'
import { Tool } from 'types'

import { Footer } from './Footer'
import { Header } from './Header'

interface Link {
  path: string
  label: string
}
interface ToolData {
  title: string
  name: Tool
  links: Link[]
  icon: React.ReactElement | null
}
interface Tools {
  admin: ToolData
  settings: ToolData
  map: ToolData
  fix: ToolData
  costs: ToolData
  docs: ToolData
  care: ToolData
}

interface Props {
  tool?: Tool
}

const tools: Tools = {
  admin: {
    title: i18n.t('header.admin'),
    name: 'admin',
    icon: null,
    links: [],
  },
  settings: {
    title: i18n.t('header.settings'),
    name: 'settings',
    icon: null,
    links: [],
  },
  map: {
    title: i18n.t('header.map'),
    name: 'map',
    icon: <MapIcon />,
    links: [
      {
        path: '/map/facilities',
        label: i18n.t('header.facilities'),
      },
      {
        path: '/map/policies',
        label: i18n.t('header.policies'),
      },
    ],
  },
  fix: {
    title: i18n.t('header.fix'),
    name: 'fix',
    icon: <FixIcon />,
    links: [],
  },
  docs: {
    title: i18n.t('header.docs'),
    name: 'docs',
    icon: <DocsIcon />,
    links: [],
  },
  costs: {
    title: 'Financiële monitor',
    name: 'costs',
    icon: <CostsIcon />,
    links: [],
  },
  care: {
    title: 'Unica Care',
    name: 'care',
    icon: null,
    links: [],
  },
}

export const Layout: React.FC<Props> = ({ tool }) => {
  const { dispatch } = useAppData()

  useEffect(() => dispatch({ type: 'setTool', payload: tool }), [tool])

  return (
    <>
      <Header title={tools[tool]?.title} toolIcon={tools[tool]?.icon} links={tools[tool]?.links} />
      <Container>
        <Outlet />
      </Container>
      <Footer />
    </>
  )
}

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  width: 100%;
  max-width: 1440px;
  min-height: 100%;
  margin: 60px 0px;
  padding: ${({ theme }) => theme.layout.padding};
  background: ${({ theme }) => theme.colors.layout.background};
  backdrop-filter: ${({ theme }) => theme.effects.layout.backdropFilter};
`
