import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Button, CardSkeleton, FacilityCard, Toolbar } from 'components'
import { CanEdit } from 'components/util/CanEdit'
import { useAppData } from 'context'
import { useFacilities } from 'hooks/api/facilities'
import { Module } from 'types'

interface GridProps {
  view?: string
}

export const FacilitiesOverview: React.FC = () => {
  const { t } = useTranslation()
  const { sortOrder, tool, view, dispatch } = useAppData()
  const { data: facilities, isLoading } = useFacilities()
  const [fadeOut, setFadeOut] = useState('')

  const sortFacilities = (order: typeof sortOrder) => {
    setFadeOut('fadeOut')
    setTimeout(() => {
      facilities?.sort((a, b) =>
        order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
      )

      dispatch({ type: 'setSortOrder', payload: order })
      setFadeOut('')
    }, facilities?.length * 50 + 150)
  }

  let tooltipText = t('facilities.explanation')
  if (tool === 'map') {
    tooltipText = t('facilities.mapExplanation')
  } else if (tool === 'fix') {
    tooltipText = t('facilities.fixExplanation')
  } else if (tool === 'docs') {
    tooltipText = t('facilities.docsExplanation')
  } else if (tool === 'costs') {
    tooltipText = t('facilities.costsExplanation')
  } else if (tool === 'care') {
    tooltipText = t('facilities.careExplanation')
  }

  return (
    <>
      <TopBar>
        <h1>{t('facilities.header')}</h1>
        <Toolbar>
          <Button
            onClick={() => sortFacilities(sortOrder === 'asc' ? 'desc' : 'asc')}
            icon={sortOrder === 'asc' ? 'south' : 'north'}
            text='AZ'
          />
          {tool === 'map' && (
            <CanEdit module={Module.PROPERTY}>
              <Button link='/map/facilities/new' icon='add' />
            </CanEdit>
          )}
        </Toolbar>
      </TopBar>
      <FacilitiesGrid view={view}>
        {isLoading ? (
          <CardSkeleton amount={5} />
        ) : (
          facilities
            .sort((a, b) =>
              sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
            )
            .map((facility, i) => {
              return (
                <FacilityCard
                  key={facility.id}
                  className={fadeOut}
                  facility={facility}
                  number={i}
                  total={facilities.length}
                  tooltip={tooltipText}
                />
              )
            })
        )}
      </FacilitiesGrid>
    </>
  )
}

const TopBar = styled.div`
  display: flex;
  margin-bottom: 38px;
  align-items: center;
`

export const FacilitiesGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
`
