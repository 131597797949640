export const getEnv = (name: string, defaultValue = ''): string => {
  return process.env[`REACT_APP_${name}`] || defaultValue
}

interface Config {
  basePath: string
  msal: {
    clientId: string
    authority: string
    redirectUri: string
  }
  management: {
    user: string
    facilities: string
  }
  address: (postcode: string, number: number, suffix?: string) => string
  clusters: (id: string) => string
  clusterUpdate: (facilityId: string, clusterId: string) => string
  clusterDelete: (id: string) => string
  contacts: (facilityId: string) => string
  contactsUpdate: (facilityId: string, contactId: string) => string
  contactsDelete: (contactId: string) => string
  documents: (facilityId: string) => string
  documentDownload: (facilityId: string, documentId: string) => string
  facilities: string
  facility: string
  facilityImg: string
  facilityImgNew: string
  policy: (facilityId: string, clusterId: string) => string
  policyImg: (policyTemplateId: string) => string
  policyImgCreate: (policyId: string) => string
  policyTemplates: string
  policyTemplate: (policyTemplateId: string) => string
  policyTemplateDelete: (policyTemplateId: string) => string
  requests: (facilityId: string) => string
  request: (facilityId: string, requestId: string) => string
  requestFile: (facilityId: string, requestId: string, fileId: string) => string
  requestFileUpdate: (facilityId: string, requestId: string) => string
  user: string
  users: string
}

export const config: Config = {
  basePath: getEnv('BASE_PATH'),
  msal: {
    clientId: getEnv('MSAL_CLIENTID'),
    authority: getEnv('MSAL_AUTHORITY'),
    redirectUri: getEnv('MSAL_REDIRECTURI'),
  },
  management: {
    user: '/management/user',
    facilities: '/management/facility/all',
  },
  address: (postcode: string, number: number, suffix?: string) =>
    `/?postcode=${postcode}&number=${number}${suffix ? `&suffix=${suffix}` : ''}`,
  clusters: (id: string) => `/facility/${id}/cluster`,
  clusterDelete: (id: string) => `/facility/cluster/${id}`,
  clusterUpdate: (facilityId: string, clusterId: string) =>
    `/facility/${facilityId}/cluster/${clusterId}`,
  contacts: (facilityId: string) => `/facility/${facilityId}/contact`,
  contactsUpdate: (facilityId: string, contactId: string) =>
    `/facility/${facilityId}/contact/${contactId}`,
  contactsDelete: (contactId: string) => `/facility/contact/${contactId}`,
  documents: (facilityId: string) => `/facility/${facilityId}/document`,
  documentDownload: (facilityId: string, documentId: string) =>
    `/facility/${facilityId}/document/${documentId}/download`,
  facilities: '/facility/all',
  facility: '/facility',
  facilityImg: '/facility/img',
  facilityImgNew: '/facility/img/upload',
  policy: (facilityId: string, clusterId: string) =>
    `/facility/${facilityId}/cluster/${clusterId}/policy`,
  policyImg: (policyTemplateId: string) => `/policy/template/${policyTemplateId}/image/download`,
  policyImgCreate: (policyId: string) => `/policy/template/${policyId}/image/upload`,
  policyTemplates: '/policy/template',
  policyTemplate: (policyTemplateId: string) => `/policy/template/${policyTemplateId}`,
  policyTemplateDelete: (policyTemplateId: string) => `/template/${policyTemplateId}`,
  requests: (facilityId: string) => `/facility/${facilityId}/request`,
  request: (facilityId: string, requestId: string) =>
    `/facility/${facilityId}/request/${requestId}`,
  requestFile: (facilityId: string, requestId: string, fileId: string) =>
    `/facility/${facilityId}/request/${requestId}/download/${fileId}`,
  requestFileUpdate: (facilityId: string, requestId: string) =>
    `/facility/${facilityId}/request/${requestId}/upload`,
  user: '/user',
  users: 'user/all',
}
