import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Request } from 'types'

export const useRequestCreate = (facilityId: string) => {
  const { axiosClient } = useAxios()

  return useMutation(async (payload: Request) => {
    const { data } = await axiosClient.post(config.requests(facilityId), payload)

    return data
  })
}
