import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Formik } from 'formik'
import i18n from 'i18n'
import * as Yup from 'yup'

import {
  AnimatedInput,
  BreadcrumbNav,
  FormFooter,
  FormGroup,
  PillSelector,
  SelectInput,
  StyledFileInput,
  StyledForm,
  SubmitButton,
} from 'components'
import { useDocumentCreate, useDocuments } from 'hooks/api/documents'
import { useFacility, useFacilityImg } from 'hooks/api/facilities/'
import { Document } from 'types'

const initialValues = (document: Document): Document => ({
  file: document?.file || '',
  status: document?.status || 'New',
  title: document?.title || '',
  type: document?.type || '',
})

const types = [
  i18n.t('documents.types.certificate'),
  i18n.t('documents.types.contract'),
  i18n.t('documents.types.energy'),
  i18n.t('documents.types.fireSafety'),
  i18n.t('documents.types.inspection'),
  i18n.t('documents.types.report'),
  i18n.t('documents.types.other'),
].map((type) => ({ label: type, value: type }))

const statuses = [
  { label: i18n.t('common.none'), value: 'None' },
  { label: i18n.t('documents.statusNew'), value: 'New' },
  { label: i18n.t('documents.statusReviewed'), value: 'Reviewd' },
]

const DocumentSchema = Yup.object().shape({
  file: Yup.string(),
  status: Yup.string().required(),
  title: Yup.string().required(),
  type: Yup.string().required(),
})

export const DocumentForm: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { facilityId, documentId } = useParams()

  const { data: facility } = useFacility(facilityId)
  const { data: documents, isFetching } = useDocuments(facilityId)
  const { mutate: createDocument } = useDocumentCreate(facilityId, {
    onSuccess: () => {
      navigate(`/docs/facilities/${facilityId}`)
    },
  })

  const document = documents?.find((document) => document.id === documentId) || null

  const { data: facilityImage } = useFacilityImg(facilityId, {
    enabled: !!facility?.img,
  })

  return (
    <>
      {facility && (
        <BreadcrumbNav
          crumbs={[
            {
              title: facility.name,
              image: facilityImage,
              link: `/docs/facilities/${facilityId}`,
            },
          ]}
          size='small'
        />
      )}

      {((document && !isFetching) || !documentId) && (
        <Formik
          initialValues={initialValues(document)}
          validationSchema={DocumentSchema}
          onSubmit={(document) => createDocument(document)}
          validateOnMount={true}
        >
          {({ values, isSubmitting, isValid, handleChange, setFieldValue }) => {
            return (
              <StyledForm>
                <h1>{document ? `Edit: ${document?.title}` : t('documents.addDocument')}</h1>
                {!documentId && (
                  <FormGroup width='narrow'>
                    <h3>1. {t('documents.file')}</h3>
                    <StyledFileInput
                      accept='.pdf'
                      name='file'
                      draggable={true}
                      setFieldValue={setFieldValue}
                      setExtraValue='title'
                    />
                  </FormGroup>
                )}
                <FormGroup width='narrow'>
                  <h3>
                    {document ? '1' : '2'}. {t('documents.title')}
                  </h3>
                  <AnimatedInput
                    label={t('documents.title')}
                    name='title'
                    maxLength={32}
                    handleChange={handleChange}
                  />
                </FormGroup>

                <FormGroup width='narrow'>
                  <h3>
                    {document ? '2' : '3'}. {t('documents.documentType')}
                  </h3>
                  <PillSelector name='type' pills={types} type='radio' />
                </FormGroup>

                <FormGroup width='narrow'>
                  <h3>
                    {document ? '3' : '4'}. {t('documents.documentStatus')}
                  </h3>
                  <SelectInput
                    name='status'
                    options={statuses}
                    selected={values.status}
                    placeholder={t('documents.selectStatus')}
                  />
                </FormGroup>

                <FormFooter>
                  <SubmitButton
                    completed={false}
                    completedText={t('documents.documentAdded')}
                    submitText={t('common.add')}
                    loading={isSubmitting}
                    disabled={!isValid}
                    type='primary'
                    submit={true}
                  />
                </FormFooter>
              </StyledForm>
            )
          }}
        </Formik>
      )}
    </>
  )
}
