import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AdminPanelSettings, Settings } from '@mui/icons-material'
import { Divider, ListItemIcon, Menu, MenuItem } from '@mui/material'

import { useUserData } from 'context/UserProvider'
import { routes } from 'routing/routes'
import { Role } from 'types'

import { SignOutItem } from './SignOutItem'

interface NavMenuProps {
  anchorEl: null | HTMLElement
  open: boolean
  handleClose: () => void
}
export const NavMenu: React.FunctionComponent<NavMenuProps> = ({ anchorEl, open, handleClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useUserData()

  return (
    <Menu
      anchorEl={anchorEl}
      id='account-menu'
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          minWidth: '160px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 20,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={() => navigate(routes.index)}>
        <ListItemIcon />
        {t('menu.overview')}
      </MenuItem>
      <Divider />
      {user.permissions.some((p) => p.roles.includes(Role.ADMIN)) && (
        <MenuItem onClick={() => navigate(routes.admin)}>
          <ListItemIcon>
            <AdminPanelSettings fontSize='small' />
          </ListItemIcon>
          {t('menu.admin')}
        </MenuItem>
      )}
      <MenuItem onClick={() => navigate(routes.settings)}>
        <ListItemIcon>
          <Settings fontSize='small' />
        </ListItemIcon>
        {t('menu.settings')}
      </MenuItem>
      <SignOutItem />
    </Menu>
  )
}
