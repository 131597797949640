import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Policy } from 'types'

export const usePolicyTemplateUpdate = (policyId: string) => {
  const { axiosClient } = useAxios()

  return useMutation(async (payload: Policy) => {
    const { data } = await axiosClient.put(config.policyTemplates, payload, {
      params: { policyId },
    })

    return data
  })
}
