import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Document } from 'types'

export const useDocumentCreate = (facilityId: string, options?: { onSuccess: () => void }) => {
  const { axiosClient } = useAxios()

  return useMutation(
    async (payload: Document) => {
      const { data } = await axiosClient.post(
        config.documents(facilityId),
        {
          title: payload.title,
          status: payload.status,
          type: payload.type,
          file: payload.file,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      return data
    },
    {
      ...options,
    },
  )
}
