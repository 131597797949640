import React from 'react'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { Layout } from 'components'
import UnAuthenticated from 'views/UnAuthenticated'

import { routes } from './routes'

export const UnAuthenticatedRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path={routes.index} element={<Navigate to={routes.login} />} />
          <Route path={routes.login} element={<UnAuthenticated />} />
          <Route path='*' element={<Navigate to={routes.login} />} />
        </Route>
      </Routes>
    </Router>
  )
}
