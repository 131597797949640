import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Button, RequestCard } from 'components'
import { useRequests } from 'hooks/api/requests'

interface Props {
  facilityId: string
}

export const PendingRequestsTab: React.FC<Props> = ({ facilityId }) => {
  const { t } = useTranslation()
  const { data: requests } = useRequests(facilityId)
  const nonResolvedRequests = requests?.filter((request) => request.status !== 'Resolved')

  return (
    <>
      <Header>
        <h3>{t('requests.pending')}</h3>
        <Button link={`/fix/facilities/${facilityId}/requests/new`} icon='add' />
      </Header>

      <RequestWrapper>
        {!nonResolvedRequests?.length ? (
          <p>{t('requests.noPending')}</p>
        ) : (
          nonResolvedRequests.map((request, i) => (
            <RequestCard
              key={request.id}
              number={i}
              request={request}
              total={nonResolvedRequests.length}
            />
          ))
        )}
      </RequestWrapper>
    </>
  )
}

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const RequestWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
`
