import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { User } from 'types'

export const useUserCreate = (options?: { onSuccess: () => void }) => {
  const { axiosClient } = useAxios()

  return useMutation(
    async (payload: User) => {
      const { data } = await axiosClient.post(config.management.user, payload)

      return data
    },
    {
      ...options,
    },
  )
}
