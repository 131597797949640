import React from 'react'

import styled from 'styled-components'

import { useAuth } from '../../context/AuthProvider'

export const SignInButton: React.FunctionComponent = () => {
  const { logIn } = useAuth()

  return <Button onClick={() => logIn()}>Sign in</Button>
}

const Button = styled.button`
  margin: 8px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.button.backgroundHover};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.button.textActive};
  font-weight: 500;
  font-stretch: condensed;
  transition: background-color 250ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.button.background};
    color: ${({ theme }) => theme.colors.button.text};
    cursor: pointer;
  }
`
