import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Facility } from 'types'

export const useFacility = (
  id: string,
  options?: { enabled?: boolean; onSuccess?: (data: Facility) => void },
) => {
  const { axiosClient } = useAxios()

  return useQuery(
    ['facility', id],
    async () => {
      const { data } = await axiosClient.get<Facility>(config.facility, {
        params: { facilityId: id },
      })

      return data
    },
    {
      ...options,
    },
  )
}
