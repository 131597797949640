import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import i18n from 'i18n'
import styled from 'styled-components'

import { Button, DropdownItem, DropdownMenu, TabBar, TabView, Toolbar } from 'components'
import { CanEdit } from 'components/util/CanEdit'
import { useAppData } from 'context'
import { useOutsideClick } from 'hooks'
import { useFacility, useFacilityDelete, useFacilityImg } from 'hooks/api/facilities'
import {
  ArchivedRequestsTab,
  ContactsTab,
  FacilityClustersTab,
  FacilityCostsTab,
  FacilityDetailsTab,
  PendingRequestsTab,
} from 'views'
import { Facility, Module, Tool } from 'types'

interface Specification {
  label: string
  value: string | number
}

interface Tab {
  label: string
  component: React.ReactElement
}

const getSpecs = (facility: Facility, tool: Tool): Specification[] => {
  switch (tool) {
    case 'map':
      return [
        { label: i18n.t('facilities.details.energyLabel'), value: facility?.energyLabel },
        { label: i18n.t('facilities.details.constructionYear'), value: facility?.details.year },
        { label: i18n.t('facilities.details.clusters'), value: facility?.clusters?.length || 0 },
        { label: i18n.t('facilities.details.averagePolicyScore'), value: 7.1 },
      ]
    case 'fix':
      return [
        { label: i18n.t('requests.requests'), value: facility?.requests?.length || 0 },
        { label: i18n.t('requests.averageMonthly'), value: '4.7 /mnth' },
      ]
    case 'costs':
      return [
        { label: i18n.t('facilities.details.energyLabel'), value: facility?.energyLabel },
        { label: i18n.t('facilities.details.avgMonthlyCosts'), value: '8.9k' },
        { label: i18n.t('facilities.details.avgQuarterlyCosts'), value: '17.8k' },
      ]
  }
}

const getTabs = (facilityId: string, tool: Tool): Tab[] => {
  switch (tool) {
    case 'map':
      return [
        {
          label: i18n.t('clusters.clusters'),
          component: <FacilityClustersTab facilityId={facilityId} />,
        },
        { label: i18n.t('contacts.contacts'), component: <ContactsTab facilityId={facilityId} /> },
        {
          label: i18n.t('facilities.details.details'),
          component: <FacilityDetailsTab facilityId={facilityId} />,
        },
      ]
    case 'fix':
      return [
        {
          label: i18n.t('fixes.pending'),
          component: <PendingRequestsTab facilityId={facilityId} />,
        },
        {
          label: i18n.t('fixes.archived'),
          component: <ArchivedRequestsTab facilityId={facilityId} />,
        },
        {
          label: i18n.t('facilities.details.details'),
          component: <FacilityDetailsTab facilityId={facilityId} />,
        },
      ]
    case 'costs':
      return [
        {
          label: i18n.t('facilities.details.costs'),
          component: <FacilityCostsTab facilityId={facilityId} />,
        },
        {
          label: i18n.t('facilities.details.details'),
          component: <FacilityDetailsTab facilityId={facilityId} />,
        },
      ]
  }
}

export const FacilityDetail: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { tool } = useAppData()
  const { facilityId } = useParams()
  const [showDropdown, setShowDropdown] = React.useState(false)
  const { data: facility } = useFacility(facilityId)
  const ref = useOutsideClick(() => setShowDropdown(false))
  const specs = getSpecs(facility, tool)
  const tabs = getTabs(facilityId, tool)

  const { data: image } = useFacilityImg(facilityId, {
    enabled: !!facility?.img,
  })

  const { mutate } = useFacilityDelete(facilityId)

  const editFacility = () => {
    navigate(`/map/facilities/${facilityId}/edit`)
  }

  return (
    <>
      <TopBar>
        {image && (
          <ImageWrapper>
            <img src={image} />
          </ImageWrapper>
        )}
        <Information>
          <h1>{facility?.name}</h1>
          <Category>{facility?.categories[0]}</Category>
          {specs && (
            <Specifications>
              {specs.map((spec) => (
                <Spec key={spec.label}>
                  <Value>{spec.value}</Value>
                  <Label>{spec.label}</Label>
                </Spec>
              ))}
            </Specifications>
          )}
        </Information>
        <CanEdit module={Module.PROPERTY}>
          <Toolbar>
            <Button
              link={`${
                (tool === 'map' && `/map/facilities/${facilityId}/clusters/new`) ||
                (tool === 'fix' && `/fix/facilities/${facilityId}/requests/new`)
              }`}
              icon='add'
            />
            <DropdownMenu innerRef={ref} show={showDropdown} setShow={setShowDropdown}>
              <DropdownItem name={t('common.edit')} action={editFacility} />
              <DropdownItem name={t('common.delete')} style='danger' action={() => mutate()} />
            </DropdownMenu>
          </Toolbar>
        </CanEdit>
      </TopBar>
      {tabs && (
        <TabBar tabs={tabs.flatMap((tab) => tab.label)}>
          {tabs.map((tab) => (
            <TabView key={tab.label} tab={tab.label}>
              {tab.component}
            </TabView>
          ))}
        </TabBar>
      )}
    </>
  )
}

const TopBar = styled.div`
  display: flex;
  margin: 50px 0 40px;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  width: 180px;
  height: 180px;
  margin: -10px 40px 0 0;
  text-align: center;

  img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 5px;
  }

  img:nth-child(2) {
    border-radius: 50%;
  }
`

const Information = styled.div`
  h1 {
    margin-top: 0;
  }
`

const Category = styled.p`
  margin: 10px 0 0 0;
`

const Specifications = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 40px;
`

const Spec = styled.div`
  margin-right: 40px;
`

const Value = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 300;
  font-stretch: condensed;
`

const Label = styled.span`
  display: block;
  font-size: 12px;
`
