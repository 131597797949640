import React from 'react'

import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { ThemeProvider } from 'styled-components'
import merge from 'ts-deepmerge'

import { useAppData } from 'context'
import { GlobalStyle } from 'styles'
import { ThemeType } from 'types'

declare module 'styled-components' {
  export interface DefaultTheme extends BaseTheme {}
}

type BaseColors = typeof colors
type DarkColors = typeof themes.dark.colors

type Colors = BaseColors & DarkColors

type BaseTheme = {
  mode: ThemeType['mode']
  colors: Colors
  effects: typeof effects
  spacing: typeof spacing
  typography: typeof typography
  card: typeof card
  layout: typeof layout
}

interface Props {
  children: React.ReactNode
}

const getTheme = ({ mode, tool }: ThemeType): BaseTheme => {
  if (!mode) return
  if (tool === 'care') mode = 'dark'

  const theme = merge(
    { mode },
    themes.base,
    themes[mode as keyof typeof themes],
    tool ? themes[tool as keyof typeof themes] : {},
  )

  return theme
}

export const Theme: React.FC<Props> = ({ children }) => {
  const { theme: mode, tool } = useAppData()

  const darkTheme = createTheme({
    ...MuiTheme,
    palette: {
      mode: mode === 'dark' ? 'dark' : 'light',
    },
  })

  return (
    <MuiThemeProvider theme={darkTheme}>
      <ThemeProvider theme={getTheme({ mode, tool })}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

const MuiTheme = createTheme({
  typography: {
    fontFamily: '"Radio Canada", "Helvetica", "Arial", "sans-serif"',
    fontSize: 14,
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 500,
  },
})

const colors = {
  berry: { hex: '#a61c3c', rgb: '166, 28, 60' },
  berryLight: { hex: '#e15172', rgb: '225, 81, 114' },
  dawn: { hex: '#585987', rgb: '88, 89, 135' },
  dawnLight: { hex: '#8484ae', rgb: '132, 132, 174' },
  jungle: { hex: '#43aa8b', rgb: '67, 170, 139' },
  jungleLight: { hex: '#6dc5ab', rgb: '109, 197, 171' },
  mango: { hex: '#dd962c', rgb: '221, 150, 44' },
  mangoLight: { hex: '#e2a850', rgb: '226, 168, 80' },
  mantis: { hex: '#73bd61', rgb: '115, 189, 97' },
  pynk: { hex: '#e84855', rgb: '232, 72, 85' },
  pynkLight: { hex: '#f6b6bb', rgb: '246, 182, 187' },
  turqoise: { hex: '#17bebb', rgb: '23, 190, 187' },
  turqoiseLight: { hex: '#5ed9d6', rgb: '94, 217, 214' },
  leaf: { hex: '#73bd61', rgb: '115, 189, 97' },
  leafLight: { hex: '#a0d88e', rgb: '160, 216, 142' },
  greyScale: {
    white: { hex: '#ffffff', rgb: '255, 255, 255' },
    lightest: { hex: '#f0f2f7', rgb: '240, 242, 247' },
    lighter: { hex: '#d6d6de', rgb: '214, 214, 222' },
    light: { hex: '#434951', rgb: '67, 73, 81' },
    medium: { hex: '#2a2e33', rgb: '42, 46, 51' },
    dark: { hex: '#191a1d', rgb: '25, 26, 29' },
    black: { hex: '#000000', rgb: '0, 0, 0' },
  },
}

const effects = {
  layout: {
    backdropFilter: 'blur(0px)',
  },
}

const spacing = {
  large: 32,
  medium: 16,
  small: 8,
  tiny: 4,
}

const typography = {
  size: 18,
  fontFamily: '"Radio Canada", sans-serif',
  fontFamilyAlt: '"Syne", sans-serif',
  fontWeight: 300,
  fontStyle: 'normal',
}

const card = {
  borderRadius: spacing.small,
}

const layout = {
  padding: '60px 60px 0',
}

const themes = {
  base: {
    colors,
    effects,
    spacing,
    typography,
    card,
    layout,
  },

  dark: {
    colors: {
      body: {
        background: colors.greyScale.medium.hex,
        backgroundRGB: colors.greyScale.medium.rgb,
        backgroundAlt: colors.greyScale.dark.hex,
        backgroundAltRGB: colors.greyScale.dark.rgb,
        backgroundSubtle: colors.greyScale.light.hex,
        bodyBackground: colors.greyScale.medium.hex,
        primary: colors.greyScale.lighter.hex,
        primaryRGB: colors.greyScale.lighter.rgb,
        primaryLight: colors.greyScale.lightest.hex,
        text: colors.greyScale.white.hex,
        icon: colors.greyScale.white.hex,
      },
      layout: {
        background: 'transparent',
      },
      header: {
        background: colors.greyScale.dark.hex,
        text: colors.greyScale.white.hex,
      },
      tabbar: {
        background: colors.greyScale.dark.hex,
      },
      card: {
        background: colors.greyScale.light.hex,
        backgroundAlt: colors.greyScale.light.hex,
        avatar: colors.greyScale.medium.hex,
        highlight: colors.greyScale.white.hex,
        subtle: colors.greyScale.lighter.hex,
        disabled: colors.greyScale.lightest.hex,
      },
      button: {
        background: colors.greyScale.light.hex,
        backgroundHover: colors.greyScale.dark.hex,
        active: colors.greyScale.dark.hex,
        text: colors.greyScale.white.hex,
        textActive: colors.greyScale.white.hex,
      },
      loginButton: {
        background: colors.turqoise.hex,
        backgroundHover: colors.turqoiseLight.hex,
        text: colors.greyScale.white.hex,
      },
      loginWrapper: {
        background: colors.greyScale.dark.hex,
        text: colors.greyScale.white.hex,
      },
      inputs: {
        background: colors.greyScale.lightest.hex,
        text: colors.greyScale.light.hex,
        errorText: colors.pynk.hex,
        placeholder: colors.greyScale.lighter.hex,
        switch: {
          border: colors.greyScale.lighter.hex,
          fill: colors.greyScale.light.hex,
          active: colors.jungle.hex,
        },
        slider: {
          fill: colors.greyScale.light.hex,
          thumb: colors.mango.hex,
        },
      },
      filePreview: {
        background: colors.greyScale.lighter.hex,
        text: colors.greyScale.medium.hex,
        button: colors.greyScale.white.hex,
      },
      modal: {
        background: `rgba(${colors.greyScale.dark.rgb}, 0.8)`,
      },
      skeleton: {
        base: colors.greyScale.medium.hex,
        highlight: colors.greyScale.light.hex,
      },
      graph: {
        background: colors.dawnLight.rgb,
        border: colors.dawnLight.rgb,
        grid: colors.dawnLight.hex,
        text: colors.dawnLight.hex,
      },
    },
  },

  light: {
    colors: {
      body: {
        background: colors.greyScale.lightest.hex,
        backgroundRGB: colors.greyScale.lightest.rgb,
        backgroundAlt: colors.greyScale.white.hex,
        backgroundAltRGB: colors.greyScale.white.rgb,
        backgroundSubtle: colors.greyScale.lighter.hex,
        bodyBackground: colors.greyScale.lightest.hex,
        primary: colors.greyScale.dark.hex,
        primaryRGB: colors.greyScale.dark.rgb,
        primaryLight: colors.greyScale.medium.hex,
        text: colors.greyScale.dark.hex,
        icon: colors.greyScale.light.hex,
      },
      layout: {
        background: 'transparent',
      },
      header: {
        background: colors.greyScale.white.hex,
        text: colors.greyScale.dark.hex,
      },
      tabbar: {
        background: colors.greyScale.white.hex,
      },
      card: {
        background: colors.greyScale.lightest.hex,
        backgroundAlt: colors.greyScale.white.hex,
        avatar: colors.greyScale.lighter.hex,
        highlight: colors.greyScale.dark.hex,
        subtle: colors.greyScale.light.hex,
        disabled: colors.greyScale.light.hex,
      },
      button: {
        background: colors.greyScale.lighter.hex,
        backgroundHover: colors.greyScale.light.hex,
        active: colors.greyScale.light.hex,
        text: colors.greyScale.medium.hex,
        textActive: colors.greyScale.white.hex,
      },
      loginButton: {
        background: colors.turqoise.hex,
        backgroundHover: colors.turqoiseLight.hex,
        text: colors.greyScale.white.hex,
      },
      loginWrapper: {
        background: colors.greyScale.lighter.hex,
        text: colors.greyScale.white.hex,
      },
      inputs: {
        background: colors.greyScale.white.hex,
        text: colors.greyScale.dark.hex,
        placeholder: colors.greyScale.light.hex,
        switch: {
          border: colors.greyScale.light.hex,
          fill: colors.greyScale.lighter.hex,
          active: colors.jungle.hex,
        },
        slider: {
          fill: colors.greyScale.lighter.hex,
          thumb: colors.mango.hex,
        },
      },
      filePreview: {
        background: colors.greyScale.lighter.hex,
        text: colors.greyScale.medium.hex,
        button: colors.greyScale.white.hex,
      },
      modal: {
        background: `rgba(${colors.greyScale.lighter.rgb}, 0.8)`,
      },
      skeleton: {
        base: colors.greyScale.lightest.hex,
        highlight: colors.greyScale.lighter.hex,
      },
      graph: {
        background: colors.dawn.rgb,
        border: colors.dawn.rgb,
        grid: colors.dawn.hex,
        text: colors.dawn.hex,
      },
    },
  },

  map: {
    colors: {
      body: {
        primary: colors.mango.hex,
        primaryRGB: colors.mango.rgb,
        primaryLight: colors.mangoLight.hex,
      },
      card: {
        highlight: colors.mango.hex,
      },
    },
  },

  fix: {
    colors: {
      body: {
        primary: colors.berry.hex,
        primaryRGB: colors.berry.rgb,
        primaryLight: colors.berryLight.hex,
      },
      card: {
        highlight: colors.berry.hex,
      },
      button: {
        active: colors.berry.hex,
        text: colors.greyScale.white.hex,
      },
    },
  },

  docs: {
    colors: {
      body: {
        primary: colors.berry.hex,
        primaryRGB: colors.berry.rgb,
        primaryLight: colors.berryLight.hex,
      },
      button: {
        active: colors.berry.hex,
        text: colors.greyScale.white.hex,
      },
    },
  },

  care: {
    effects: {
      layout: {
        backdropFilter: 'blur(5px)',
      },
    },
    colors: {
      body: {
        background: 'transparent',
        bodyBackground: `url(${window.location.origin}/images/unica-care-summer-bg.jpg) center / cover no-repeat`,
        primary: colors.leaf.hex,
        primaryRGB: colors.leaf.rgb,
        primaryLight: colors.leafLight.hex,
      },
      layout: {
        background: `rgba(${colors.greyScale.dark.rgb}, 0.7)`,
      },
      header: {
        background: 'transparent',
        text: colors.greyScale.medium.hex,
      },
      tabbar: {
        background: 'transparent',
      },
      card: {
        background: `rgba(${colors.greyScale.white.rgb}, 0.2)`,
        backgroundAlt: 'transparent',
        avatar: colors.greyScale.medium.hex,
        highlight: colors.greyScale.white.hex,
        subtle: colors.greyScale.lighter.hex,
      },
      button: {
        active: colors.leaf.hex,
        text: colors.greyScale.white.hex,
      },
      skeleton: {
        base: 'transparent',
        highlight: `rgba(${colors.greyScale.lighter.rgb}, 0.4)`,
      },
    },
    card: {
      borderRadius: 0,
    },
    layout: {
      padding: '30px 60px',
    },
  },

  costs: {},
  admin: {},
  settings: {},
}
