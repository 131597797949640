import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import { Button, ModalFooter, ModalHeader, styleModalBox, SubmitButton } from 'components'
import { Facility } from 'types'

interface Props {
  facilities: Facility[]
  open: boolean
  handleClose: () => void
  handleSubmit: (id: string) => void
}
export const FacilityModal: React.FunctionComponent<Props> = ({
  facilities,
  open,
  handleClose,
  handleSubmit,
}) => {
  const { t } = useTranslation()
  const [facilityId, setFacilityId] = useState('')
  const facilityOptions = facilities.map((f) => ({
    value: f.id,
    label: f.name,
  }))

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby='modal-add-facility'>
      <Box sx={styleModalBox}>
        <ModalHeader>
          <Button icon='close' onClick={handleClose} />
        </ModalHeader>
        <Select
          placeholder={t('admin.user.selectFacility')}
          name='role'
          options={facilityOptions}
          onChange={(newValue) => {
            setFacilityId(newValue.value)
          }}
        />
        <ModalFooter>
          <SubmitButton
            submitText='add facility'
            disabled={!facilityId}
            onClick={() => {
              handleSubmit(facilityId)
              setFacilityId('')
              handleClose()
            }}
          />
        </ModalFooter>
      </Box>
    </Modal>
  )
}
