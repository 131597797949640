import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Policy } from 'types'

export const usePolicyTemplate = (policiTemplateId: string, options?: { enabled: boolean }) => {
  const { axiosClient } = useAxios()

  return useQuery(
    ['policyTemplate', policiTemplateId],
    async () => {
      const { data } = await axiosClient.get<Policy>(config.policyTemplate(policiTemplateId))

      return data
    },
    {
      ...options,
    },
  )
}
