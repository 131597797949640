import React, { FunctionComponent } from 'react'

import { useUserData } from 'context/UserProvider'
import { Module, Role } from 'types'

interface ModuleProps {
  module: Module
  children: React.ReactElement
}

export const CanEdit: FunctionComponent<ModuleProps> = ({ module, children }) => {
  const { user } = useUserData()
  const canEdit = (amodule: Module) => {
    return (
      user.permissions &&
      user.permissions
        .find((p) => p.module === amodule)
        .roles?.some((r) => r === Role.CONTRIBUTOR || r === Role.ADMIN)
    )
  }

  return canEdit(module) ? children : null
}
