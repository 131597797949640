import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useIsAuthenticated } from '@azure/msal-react'
import styled from 'styled-components'

import { ReactComponent as HeroesLogo } from 'assets/Logo-Heroes-altgreen.svg'
import { ReactComponent as UnicaLogo } from 'assets/unica-logo.svg'
import { Button, Navbar } from 'components'
import { useAuth } from 'context/AuthProvider'

import { AuthenticatedMenu } from './AuthenticatedMenu'
import { SignInButton } from './SignInButton'

interface Props {
  toolIcon: React.ReactElement
  title: string
  links: Link[] | []
}

type Link = {
  path: string
  label: string
}

export const Header: React.FC<Props> = ({ toolIcon, title, links }) => {
  const isAuthenticated = useIsAuthenticated()
  const { activeAccount } = useAuth()
  const navigate = useNavigate()

  return (
    <HeaderWrapper>
      <Container>
        <PrimaryNav>
          {window.location.pathname !== '/' ? (
            window.location.pathname !== '/login' ? (
              <Button icon='leftArrow' link='/' />
            ) : null
          ) : //
          isAuthenticated && activeAccount.username.includes('heroes') ? (
            <StyledHeroesLogo alt='Heroes Logo' />
          ) : (
            <StyledUnicaLogo alt='Unica Logo' />
          )}
          <HomeButtonWrapper
            onClick={() => {
              window.location.pathname.includes('facilities')
                ? navigate('facilities')
                : navigate('/admin')
            }}
          >
            {toolIcon}
            <span>{title}</span>
          </HomeButtonWrapper>
        </PrimaryNav>
        <Navbar links={links} />
        {isAuthenticated ? <AuthenticatedMenu /> : <SignInButton />}
      </Container>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.header.background};
  z-index: 500;
`

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
`

const PrimaryNav = styled.div`
  display: flex;
  align-items: center;

  button {
    position: relative;
    margin: 0 15px 0 5px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 2px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.body.background};
    }
  }
`

const HomeButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-grow: 1;
  gap: 10px;
  cursor: pointer;

  svg {
    width: auto;
    height: 30px;

    path {
      fill: ${({ theme }) => theme.colors.body.icon};
    }
  }
`

const StyledUnicaLogo = styled(UnicaLogo)`
  display: flex;
  width: 70px;
  margin: 0 15px;

  path {
    fill: ${({ theme }) => theme.colors.body.icon};
  }
`

const StyledHeroesLogo = styled(HeroesLogo)`
  display: flex;
  width: 70px;
  margin: 15px 90px;

  path {
    fill: ${({ theme }) => theme.colors.body.icon};
  }
`
