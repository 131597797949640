import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Document } from 'types'

export const useDocumentDownload = (document: Document) => {
  const { axiosClient } = useAxios()

  return useQuery(['documentDownload', document.id], async () => {
    const { data } = await axiosClient.get<Blob>(
      config.documentDownload(document.facilityId, document.id),
      { responseType: 'blob' },
    )

    return URL.createObjectURL(data)
  })
}
