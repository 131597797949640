import React from 'react'

import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { Alert, LinearProgress } from '@mui/material'

const UnAuthenticated: React.FunctionComponent = () => {
  const { inProgress } = useMsal()

  return (
    <>
      {inProgress === InteractionStatus.None || inProgress === InteractionStatus.Login ? (
        <Alert severity='info'>You are not signed in! Please sign in.</Alert>
      ) : (
        <LinearProgress />
      )}
    </>
  )
}

export default UnAuthenticated
