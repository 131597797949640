import { useQueries } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { RequestFile } from 'types'

export const useRequestFiles = (facilityId: string, requestId: string, files: RequestFile[]) => {
  const { axiosClient } = useAxios()

  return useQueries({
    queries: files.map((file) => ({
      queryKey: ['requestFile', file.id],
      queryFn: () => axiosClient.get<Blob>(config.requestFile(facilityId, requestId, file.id)),
    })),
  })
}
