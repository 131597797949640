import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'

export const useClusterDelete = (facilityId: string, clusterId: string) => {
  const { axiosClient } = useAxios()

  return useMutation(
    async () => {
      const { data } = await axiosClient.delete(config.clusterDelete(clusterId))

      return data
    },
    {
      onSuccess: () => {
        window.location.href = `/map/facilities/${facilityId}`
      },
    },
  )
}
