import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Facility } from 'types'

export const useFacilities = () => {
  const { axiosClient } = useAxios()

  return useQuery(['facilities'], async () => {
    const { data } = await axiosClient.get<Facility[]>(config.facilities)

    return data
  })
}
