import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'

export const useRequestFileUpdate = (facilityId: string, requestId: string) => {
  const { axiosClient } = useAxios()

  return useMutation(async (payload: File) => {
    const id = await axiosClient.put(
      config.requestFileUpdate(facilityId, requestId),
      {
        file: payload,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    return id
  })
}
