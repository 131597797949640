import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'

export const useFacilityImg = (
  id: string,
  options?: { enabled?: boolean; onSuccess?: (data: string) => void },
) => {
  const { axiosClient } = useAxios()

  return useQuery(
    ['facilityImg', id],
    async () => {
      const { data } = await axiosClient.get<Blob>(config.facilityImg, {
        responseType: 'blob',
        params: { facilityId: id },
      })

      return URL.createObjectURL(data)
    },
    {
      ...options,
    },
  )
}
