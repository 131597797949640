import React from 'react'

import { AuthState, useAuth } from 'context/AuthProvider'
import { AxiosProvider } from 'context/AxiosProvider'
import { UserProvider } from 'context/UserProvider'
import InitialLoading from 'views/InitialLoading'

import { AuthenticatedRoutes } from './AuthenticatedRoutes'
import { UnAuthenticatedRoutes } from './UnAuthenticatedRoutes'

export function Router(): JSX.Element {
  const { authState } = useAuth()

  return authState === AuthState.INITIATING ? (
    <InitialLoading />
  ) : authState === AuthState.LOGGED_IN ? (
    <AxiosProvider>
      <UserProvider>
        <AuthenticatedRoutes />
      </UserProvider>
    </AxiosProvider>
  ) : (
    <UnAuthenticatedRoutes />
  )
}
