import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Request } from 'types'

export const useRequestUpdate = (
  facilityId: string,
  requestId: string,
  options?: { onSuccess: () => void },
) => {
  const { axiosClient } = useAxios()

  return useMutation(
    async (payload: Request) => {
      const { data } = await axiosClient.put(config.request(facilityId, requestId), payload)

      return data
    },
    { ...options },
  )
}
