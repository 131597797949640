import { useMutation, useQueryClient } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Contact } from 'types'

export const useContactCreate = (facilityId: string) => {
  const { axiosClient } = useAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (payload: Contact) => {
      const { data } = await axiosClient.post(config.contacts(facilityId), payload)

      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contacts', facilityId])
      },
    },
  )
}
