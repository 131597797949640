import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Contact } from 'types'

export const useContacts = (facilityId: string) => {
  const { axiosClient } = useAxios()

  return useQuery(['contacts', facilityId], async () => {
    const { data } = await axiosClient.get<Contact[]>(config.contacts(facilityId))

    return data
  })
}
