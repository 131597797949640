import { Policy } from 'types'

export const PriorityLevels = [
  {
    label: '> 100 000 €',
    value: 'P10',
    details: {
      processDisruption: '100%',
      socialImpact: 'irreversible',
      environmentalImpact: 'irreversible',
      mandate: '20K€',
      healthAndSafetyImpact: 'irreversible',
      timeToResponse: '1h',
      timeToResolve: '4h',
      minMaintenanceLevel: 'plus',
    },
  },
  {
    label: '> 50 000 €',
    value: 'P9',
    details: {
      processDisruption: '75 - 100%',
      socialImpact: 'serious',
      environmentalImpact: 'serious',
      mandate: '10K€',
      healthAndSafetyImpact: 'serious',
      timeToResponse: '1h',
      timeToResolve: '6h',
      minMaintenanceLevel: 'plus',
    },
  },
  {
    label: '> 10 000 €',
    value: 'P8',
    details: {
      processDisruption: '50 - 75%',
      socialImpact: 'serious',
      environmentalImpact: 'serious',
      mandate: '5K€',
      healthAndSafetyImpact: 'serious',
      timeToResponse: '2h',
      timeToResolve: '6h',
      minMaintenanceLevel: 'plus',
    },
  },
  {
    label: '> 5 000 €',
    value: 'P7',
    details: {
      processDisruption: '30 - 50%',
      socialImpact: 'noticeable',
      environmentalImpact: 'noticeable',
      mandate: '4K€',
      healthAndSafetyImpact: 'noticeable',
      timeToResponse: '2h',
      timeToResolve: '12h',
      minMaintenanceLevel: 'basic',
    },
  },
  {
    label: '> 1 000 €',
    value: 'P6',
    details: {
      processDisruption: '20 - 30%',
      socialImpact: 'noticeable',
      environmentalImpact: 'noticeable',
      mandate: '2K€',
      healthAndSafetyImpact: 'noticeable',
      timeToResponse: '4h',
      timeToResolve: '12h',
      minMaintenanceLevel: 'basic',
    },
  },
  {
    label: '> 250 €',
    value: 'P5',
    details: {
      processDisruption: '10 - 20%',
      socialImpact: 'moderate',
      environmentalImpact: 'moderate',
      mandate: '2K€',
      healthAndSafetyImpact: 'moderate',
      timeToResponse: '4h',
      timeToResolve: '16h',
      minMaintenanceLevel: 'basic',
    },
  },
  {
    label: '> 150 €',
    value: 'P4',
    details: {
      processDisruption: '5 - 10%',
      socialImpact: 'moderate',
      environmentalImpact: 'moderate',
      mandate: '1K€',
      healthAndSafetyImpact: 'moderate',
      timeToResponse: '4h',
      timeToResolve: '12h',
      minMaintenanceLevel: 'minimum',
    },
  },
  {
    label: '> 100 €',
    value: 'P3',
    details: {
      processDisruption: '3 - 5%',
      socialImpact: 'light',
      environmentalImpact: 'light',
      mandate: '750€',
      healthAndSafetyImpact: 'light',
      timeToResponse: '6h',
      timeToResolve: '24h',
      minMaintenanceLevel: 'minimum',
    },
  },
  {
    label: '> 15 €',
    value: 'P2',
    details: {
      processDisruption: '1 - 3%',
      socialImpact: 'light',
      environmentalImpact: 'light',
      mandate: '500€',
      healthAndSafetyImpact: 'light',
      timeToResponse: '8h',
      timeToResolve: '32h',
      minMaintenanceLevel: 'minimum',
    },
  },
  {
    label: '< 15 €',
    value: 'P1',
    details: {
      processDisruption: '0%',
      socialImpact: 'no',
      environmentalImpact: 'no',
      mandate: '500€',
      healthAndSafetyImpact: 'no',
      timeToResponse: '12h',
      timeToResolve: '48h',
      minMaintenanceLevel: 'minimum',
    },
  },
  {
    label: null,
    value: 'P0',
    details: {
      processDisruption: '\u2014',
      socialImpact: '\u2014',
      environmentalImpact: '\u2014',
      mandate: '\u2014',
      healthAndSafetyImpact: '\u2014',
      timeToResponse: '\u2014',
      timeToResolve: '\u2014',
      minMaintenanceLevel: '\u2014',
    },
  },
]

export const MainGoals: Policy['goals'] = {
  safety: {
    health: 4.5,
    usability: 4.5,
    energy: 4.5,
    environment: 4.5,
    society: 4.5,
  },
  health: {
    usability: 4.5,
    energy: 4.5,
    environment: 4.5,
    safety: 4.5,
    society: 4.5,
  },
  usability: {
    health: 4.5,
    energy: 4.5,
    environment: 4.5,
    safety: 4.5,
    society: 4.5,
  },
  energy: {
    health: 4.5,
    usability: 4.5,
    environment: 4.5,
    safety: 4.5,
    society: 4.5,
  },
  environment: {
    health: 4.5,
    usability: 4.5,
    energy: 4.5,
    safety: 4.5,
    society: 4.5,
  },
  society: {
    health: 4.5,
    usability: 4.5,
    energy: 4.5,
    environment: 4.5,
    safety: 4.5,
  },
}

export const SubGoals: Policy['subgoals'] = {
  energy: {
    energyEfficiency: {
      emission: 4.5,
    },
    emission: {
      energyEfficiency: 4.5,
    },
  },
  environment: {
    culturalHistoricalValue: {
      imageAspects: 4.5,
      knowledgeLoss: 4.5,
    },
    imageAspects: {
      culturalHistoricalValue: 4.5,
      knowledgeLoss: 4.5,
    },
    knowledgeLoss: {
      culturalHistoricalValue: 4.5,
      imageAspects: 4.5,
    },
  },
  health: {
    comfortAndCosiness: {
      wellbeing: 4.5,
    },
    wellbeing: {
      comfortAndCosiness: 4.5,
    },
  },
  safety: {
    limitCasualties: {
      limitDamageThirdParties: 4.5,
    },
    limitDamageThirdParties: {
      limitCasualties: 4.5,
    },
  },
  society: {
    sustainability: {
      limitDamageSurrounding: 4.5,
    },
    limitDamageSurrounding: {
      sustainability: 4.5,
    },
  },
  usability: {
    limitDamageUserProcess: {
      limitDamageOwnerFinancially: 4.5,
      safetyInternalExternal: 4.5,
    },
    limitDamageOwnerFinancially: {
      limitDamageUserProcess: 4.5,
      safetyInternalExternal: 4.5,
    },
    safetyInternalExternal: {
      limitDamageUserProcess: 4.5,
      limitDamageOwnerFinancially: 4.5,
    },
  },
}
