import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { Button } from 'components'

interface MenuProps {
  show?: boolean
  setShow?: (show: boolean) => void
  innerRef?: React.MutableRefObject<HTMLDivElement>
  alignItem?: 'start' | 'end' | 'center'
  children: React.ReactNode
}

interface ItemProps {
  name: string
  style?: string
  action: () => void
}

export const DropdownMenu: React.FC<MenuProps> = ({
  show,
  setShow,
  innerRef: ref,
  alignItem = 'start',
  children,
}) => {
  const [transition, setTransition] = useState('')

  useEffect(() => {
    if (transition !== '' || show) {
      setTransition(show ? 'transition-in' : 'transition-out')
    }
  }, [show])

  return (
    <DropdownContainer ref={ref} className='dropDown' alignItem={alignItem}>
      <Button onClick={() => setShow(!show)} icon='dots' />
      <ItemList className={transition}>{children}</ItemList>
    </DropdownContainer>
  )
}

export const DropdownItem: React.FC<ItemProps> = ({ name, style, action }) => {
  return (
    <Item className={style} onClick={action}>
      {name}
    </Item>
  )
}

const DropdownContainer = styled.div<{ alignItem: 'start' | 'end' | 'center' }>`
  display: flex;
  height: 60px;
  justify-content: end;

  button {
    min-width: auto;
    height: auto;
  }
`

const ItemList = styled.ul`
  display: flex;
  padding: 0;
  position: absolute;
  margin: 58px 0px 0px;
  flex-flow: column;
  list-style-type: none;
  transform: scaleY(0);
  transform-origin: top center;
  z-index: 9;

  &.transition-in {
    animation: growDown 300ms ease-in-out forwards;
  }

  &.transition-out {
    animation: growUp 300ms ease-in-out forwards;
  }

  @keyframes growDown {
    0% {
      opacity: 0;
      transform: scaleY(0);
    }
    80% {
      opacity: 1;
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @keyframes growUp {
    0% {
      transform: scaleY(1);
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      transform: scaleY(0.9);
    }
    100% {
      opacity: 0;
      transform: scaleY(0);
    }
  }
`

const Item = styled.li`
  border: 1px solid ${({ theme }) => theme.colors.body.backgroundSubtle};
  margin-top: 2px;
  padding: 8px 32px;
  background-color: ${({ theme }) => theme.colors.body.backgroundAlt};
  border-radius: 4px;
  text-align: center;
  text-transform: capitalize;

  &.danger {
    color: ${({ theme }) => theme.colors.pynk.hex};
  }

  &:hover {
    cursor: pointer;
  }
`
