import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Facility } from 'types'

export const useFacilityCreate = () => {
  const { axiosClient } = useAxios()

  return useMutation(async (payload: Facility) => {
    const { data } = await axiosClient.post(config.facility, payload)

    return data
  })
}
