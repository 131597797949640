import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { User } from 'types'

export const useUser = () => {
  const { axiosClient } = useAxios()

  return useQuery(['user'], async () => {
    const { data } = await axiosClient.get<User>(config.user)

    return data
  })
}
