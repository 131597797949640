import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Facility } from 'types'

export const useFacilityUpdate = (id: string) => {
  const { axiosClient } = useAxios()

  return useMutation(async (payload: Facility) => {
    const { data } = await axiosClient.put(config.facility, payload, {
      params: { facilityId: id },
    })

    return data
  })
}
