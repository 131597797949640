import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Request } from 'types'

export const useRequests = (facilityId: string) => {
  const { axiosClient } = useAxios()

  return useQuery(['requests', facilityId], async () => {
    const { data } = await axiosClient.get<Request[]>(config.requests(facilityId), {
      params: { facilityId: facilityId },
    })

    return data
  })
}
