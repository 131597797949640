import React from 'react'
import { useTranslation } from 'react-i18next'

import Logout from '@mui/icons-material/Logout'
import { ListItemIcon, MenuItem } from '@mui/material'

import { useAuth } from '../../../context/AuthProvider'

export const SignOutItem: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { logOut } = useAuth()

  return (
    <MenuItem onClick={() => logOut()}>
      <ListItemIcon>
        <Logout fontSize='small' />
      </ListItemIcon>
      {t('header.logout')}
    </MenuItem>
  )
}
