import React from 'react'

import { DarkMode, LightMode } from '@mui/icons-material'
import { Switch } from '@mui/material'
import styled from 'styled-components'

import { useAppData } from 'context'

export const Footer: React.FC = () => {
  const { theme, dispatch } = useAppData()

  return (
    <Container>
      <LinksContainer>
        <Links>
          <ul>
            <li>
              <a href='https://www.unica.nl/'>
                <span>Unica</span>
              </a>
            </li>
            <li>
              <a href='https://www.unica.nl/privacy_statement'>
                <span>Privacy</span>
              </a>
            </li>
            <li>
              <a href='https://building-insight.nl/conditions/Unica%20-%20Algemene%20Voorwaarden%20Webshop%20-%2020201130.pdf'>
                <span>Terms</span>
              </a>
            </li>
            <li>
              <ThemeSwitcher>
                <LightMode className={theme === 'dark' ? 'subtle lightmode' : 'lightmode'} />
                <Switch
                  onChange={() =>
                    dispatch({ payload: theme === 'dark' ? 'light' : 'dark', type: 'setTheme' })
                  }
                  checked={theme === 'dark'}
                />
                <DarkMode className={theme === 'light' ? 'subtle darkmode' : 'darkmode'} />
              </ThemeSwitcher>
            </li>
          </ul>
        </Links>
      </LinksContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`

const LinksContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 20px;
`

const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  justify-content: flex-start;
  align-items: end;

  ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0 10px;
    padding: 0;
    a {
      transition: all 0.2s ease-in-out;
      &:hover {
        color: ${({ theme }) => theme.colors.mangoLight.hex};
      }
    }
  }
`

const ThemeSwitcher = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 50px;

  .subtle {
    opacity: 0.5;
  }

  .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.colors.inputs.switch.fill};
  }

  .MuiSwitch-switchBase {
    &.Mui-checked {
      .MuiSwitch-thumb {
        background-color: ${({ theme }) => theme.colors.mango.hex};
      }

      & + .MuiSwitch-track {
        background-color: ${({ theme }) => theme.colors.mango.hex};
        opacity: 1;
      }
    }
  }
`
