import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Cluster } from 'types'

export {}

export const useClusters = (id: string, options?: { enabled?: boolean }) => {
  const { axiosClient } = useAxios()

  return useQuery(
    ['clusters', id],
    async () => {
      const { data } = await axiosClient.get<Cluster[]>(config.clusters(id))

      return data
    },
    {
      ...options,
    },
  )
}
