import React, { useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { IconButton } from '@mui/material'

import { NavMenu } from './NavMenu'
import WelcomeName from './WelcomeName'

export const AuthenticatedMenu: React.FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <WelcomeName />
      <IconButton
        onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
        disableRipple
        size='large'
        edge='start'
        color='inherit'
        aria-label='open drawer'
      >
        <MenuIcon />
      </IconButton>
      <NavMenu anchorEl={anchorEl} open={open} handleClose={() => setAnchorEl(null)} />
    </>
  )
}
