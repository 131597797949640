import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FieldArray, useFormikContext } from 'formik'
import i18n from 'i18n'
import styled from 'styled-components'

import { Button, Toolbar } from 'components'
import { PermissionModal } from 'components/modal'
import { Permission, Role, UserExtended } from 'types'

type OptionType = {
  value: Role
  label: string
}
const options: OptionType[] = [
  { value: Role.READER, label: i18n.t('admin.user.roles.reader') },
  { value: Role.CONTRIBUTOR, label: i18n.t('admin.user.roles.contributer') },
  { value: Role.ADMIN, label: i18n.t('admin.user.roles.admin') },
]

export const PermissionsTable: React.FC = () => {
  const { values } = useFormikContext<Partial<UserExtended>>()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <>
      <TopBar>
        <h3>{t('admin.user.permissionsTitle')}</h3>
        <Toolbar>
          {values.permissions.length < 5 && <Button icon='add' onClick={() => setOpen(true)} />}
        </Toolbar>
      </TopBar>
      <TableContainer component={Paper} sx={{ overflowX: 'unset' }}>
        <Table aria-label='permissions table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '50%' }}>{t('admin.table.permissions.module')}</TableCell>
              <TableCell>{t('admin.table.permissions.role')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody sx={{ '& > tr:last-child > td': { border: 0 } }}>
            <FieldArray name='permissions'>
              {({ form, push, replace, remove }) => {
                const { values } = form

                return (
                  <>
                    {values.permissions.length ? (
                      <>
                        {values.permissions.map((p: Permission, index: number) => (
                          <TableRow key={p.module}>
                            <TableCell>{p.module}</TableCell>
                            <TableCell>
                              <Select
                                value={options.find((o) => o.value === p.roles[0])}
                                placeholder={t('admin.user.selectRole')}
                                name='role'
                                options={options}
                                onChange={(newValue) => {
                                  const permission = { module: p.module, roles: [newValue.value] }
                                  replace(index, permission)
                                }}
                              />
                            </TableCell>
                            <TableCell align='right'>
                              <IconButton
                                aria-label='expand row'
                                size='small'
                                onClick={() => remove(index)}
                              >
                                <DeleteOutlineIcon fontSize='small' />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2}>
                          {t('admin.table.permissions.assignPermissions')}
                        </TableCell>
                      </TableRow>
                    )}
                    <PermissionModal
                      permissions={values.permissions}
                      open={open}
                      handleClose={() => setOpen(false)}
                      handleSubmit={(permission) => {
                        push(permission)
                        setOpen(false)
                      }}
                    />
                  </>
                )
              }}
            </FieldArray>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const TopBar = styled.div`
  display: flex;
  align-items: center;
`
