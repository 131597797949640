import React, { useState } from 'react'

import { Box } from '@mui/material'
import styled from 'styled-components'

import { ContactPersonForm, DropdownItem, DropdownMenu, Modal } from 'components'
import { CanEdit } from 'components/util/CanEdit'
import { useOutsideClick } from 'hooks'
import { useContactDelete } from 'hooks/api/contacts'
import { cardBody, cardContainer, cardHeader } from 'styles'
import { Contact, Module } from 'types'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

interface Props {
  contact: Contact
  number: number
  total: number
}

export const ContactCard: React.FC<Props> = ({ contact, number, total }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const ref = useOutsideClick(() => setShowDropdown(false))
  const [open, setOpen] = React.useState<'closed' | 'open'>('closed')
  const handleOpen = () => setOpen('open')
  const handleClose = () => setOpen('closed')

  const { mutate: deletecContact } = useContactDelete(contact.id, contact.facilityId)

  return (
    <>
      <CardContainer number={number} total={total}>
        <CardHeader>
          <h3>
            {contact.firstName} {contact.lastName}
          </h3>
          <CanEdit module={Module.PROPERTY}>
            <DropdownMenu innerRef={ref} show={showDropdown} setShow={setShowDropdown}>
              <DropdownItem name='edit' action={handleOpen} />
              <DropdownItem name='delete' style='danger' action={() => deletecContact()} />
            </DropdownMenu>
          </CanEdit>
        </CardHeader>
        <CardBody>
          <p>{contact.title}</p>
          <a href={`mailto:${contact.email}`}>{contact.email}</a>
          <p>{contact.phone}</p>
        </CardBody>
      </CardContainer>
      <Modal
        state={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ContactPersonForm
            contact={contact}
            facilityId={contact.facilityId}
            closeForm={() => handleClose()}
          />
        </Box>
      </Modal>
    </>
  )
}

const CardContainer = styled.div`
  ${cardContainer}

  flex-flow: row wrap;
  padding: 12px 20px;

  &:hover {
    cursor: default;
  }
`

const CardHeader = styled.div`
  ${cardHeader}
`

const CardBody = styled.div`
  ${cardBody}
`
