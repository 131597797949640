import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { getEnv } from 'config'
import styled from 'styled-components'

import { useAuth } from 'context/AuthProvider'
import { useUserData } from 'context/UserProvider'
import { cardBody, cardContainer } from 'styles'
import { Module } from 'types'

export const Home: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { getToken } = useAuth()
  const { user } = useUserData()

  const handleNavigate = async () => {
    const token = await getToken()
    const url = getEnv('BUILDING_INSIGHTS_URL')

    window.open(`${url}${token}`, '_blank')
  }

  const modules = user && user.permissions ? user.permissions.map((p) => p.module) : []
  const isRouteEnabled = (module: Module) => {
    return modules.includes(module)
  }

  return (
    <>
      <Container>
        <CardContainer
          enabled={isRouteEnabled(Module.PROPERTY)}
          onClick={() => navigate('/map/facilities')}
        >
          <CardBody>
            <MapTitle>{t('home.mapTool')}</MapTitle>
            <span>{t('home.mapSubtitle')}</span>
          </CardBody>
        </CardContainer>
        <CardContainer
          enabled={isRouteEnabled(Module.FINANCIAL)}
          onClick={() => navigate('/costs/facilities')}
        >
          <CardBody>
            <CostsTitle>{t('home.costsTool')}</CostsTitle>
            <span>{t('home.costsSubtitle')}</span>
          </CardBody>
        </CardContainer>
        <CardContainer
          enabled={isRouteEnabled(Module.SERVICE)}
          onClick={() => navigate('/fix/facilities')}
        >
          <CardBody>
            <FixTitle>{t('home.fixTool')}</FixTitle>
            <span>{t('home.fixSubtitle')}</span>
          </CardBody>
        </CardContainer>
        <CardContainer
          enabled={isRouteEnabled(Module.MYUNICA)}
          onClick={() => navigate('/docs/facilities')}
        >
          <CardBody>
            <DocsTitle>{t('home.docsTool')}</DocsTitle>
            <span>{t('home.docsSubtitle')}</span>
          </CardBody>
        </CardContainer>
        <CardContainer
          enabled={isRouteEnabled(Module.SUSTAINABILITY)}
          onClick={() => navigate('/care/facilities')}
        >
          <CardBody>
            <CareTitle>{t('home.careTool')}</CareTitle>
            <span>{t('home.careSubtitle')}</span>
          </CardBody>
        </CardContainer>
        <CardContainer enabled={user.BIAccess} onClick={() => user.BIAccess && handleNavigate()}>
          <CardBody>
            <BITitle>{t('home.biTool')}</BITitle>
            <span>{t('home.biSubtitle')}</span>
          </CardBody>
        </CardContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1440px;
  justify-content: center;

  > div {
    height: 220px;
  }
`
interface EnabledProps {
  enabled?: boolean
}
const CardContainer = styled.div<EnabledProps>`
  ${({ enabled }) => enabled && cardContainer};

  border-radius: 0;
  padding-left: 40px;

  h1 {
    font-size: 48px;
    margin-bottom: 5px;
  }
  background-color: ${({ theme, enabled }) =>
    enabled ? theme.colors.card.backgroundAlt : theme.colors.card.disabled};
`

const CardBody = styled.div`
  ${cardBody}
`

const MapTitle = styled.h1`
  color: ${({ theme }) => theme.colors.mangoLight.hex};
`
const CostsTitle = styled.h1`
  color: ${({ theme }) => theme.colors.dawnLight.hex};
`
const FixTitle = styled.h1`
  color: ${({ theme }) => theme.colors.berryLight.hex};
`

const DocsTitle = styled.h1`
  color: ${({ theme }) => theme.colors.greyScale.lighter.hex};
`

const CareTitle = styled.h1`
  color: ${({ theme }) => theme.colors.mantis.hex};
`

const BITitle = styled.h1`
  color: ${({ theme }) => theme.colors.turqoise.hex};
`
