import React, { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { PriorityLevels } from 'hooks/api/policies'

type Level = (typeof PriorityLevels)[0]

interface Props {
  handleChange: (level: string) => void
}

export const PriorityLevelInput: React.FC<Props> = ({ handleChange }) => {
  const { t } = useTranslation()
  const [selectedLevel, setSelectedLevel] = useState('')
  const [priorityLevel, setPriorityLevel] = useState<Level>(
    PriorityLevels.find((l) => l.value === 'P0'),
  )
  const [selectedPriority, setSelectedPriority] = useState('')

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLButtonElement
    const levelEnum = target.getAttribute('data-value')

    setSelectedLevel(levelEnum)
    setPriorityLevel(PriorityLevels.find((lvl) => lvl.value === levelEnum))

    if (['P1', 'P2', 'P3'].includes(levelEnum)) {
      setSelectedPriority('low')
    } else if (['P4', 'P5', 'P6', 'P7'].includes(levelEnum)) {
      setSelectedPriority('medium')
    } else if (['P8', 'P9', 'P10'].includes(levelEnum)) {
      setSelectedPriority('high')
    }

    handleChange(levelEnum)
  }

  return (
    <OuterWrapper>
      <SelectorWrapper>
        <ValidityWrapper className={selectedPriority}>
          {t('policies.priority.validity')}
        </ValidityWrapper>
        <PriorityWrapper>
          <span className={selectedPriority === 'high' ? 'active' : ''}>
            {t('policies.priority.high')}
            <br />
            {t('policies.priority.priority')}
          </span>
          <span className={selectedPriority === 'medium' ? 'active' : ''}>
            {t('policies.priority.medium')}
            <br />
            {t('policies.priority.priority')}
          </span>
          <span className={selectedPriority === 'low' ? 'active' : ''}>
            {t('policies.priority.low')}
            <br />
            {t('policies.priority.priority')}
          </span>
        </PriorityWrapper>
        <LevelWrapper>
          {PriorityLevels.map((level) =>
            level.label ? (
              <button
                key={level.value}
                className={selectedLevel === level.value ? 'active' : ''}
                data-value={level.value}
                onClick={handleClick}
                type='button'
              >
                {level.label}
              </button>
            ) : null,
          )}
        </LevelWrapper>
      </SelectorWrapper>
      {priorityLevel && (
        <DetailsWrapper>
          <h3>Involved</h3>
          {Object.keys(priorityLevel.details).map((key, i) => (
            <Detail key={i}>
              <span>{priorityLevel.details[key as keyof typeof priorityLevel.details]}</span>
              <span>{t(`policies.priority.details.${key}`)}</span>
            </Detail>
          ))}
        </DetailsWrapper>
      )}
    </OuterWrapper>
  )
}

const OuterWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  height: 400px;
  font-size: 18px;
  font-weight: 300;
  font-stretch: condensed;
`

const SelectorWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
`

const DetailsWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row wrap;
  width: 325px;
  margin-left: 60px;
  justify-content: flex-start;

  h3 {
    flex: 1 0 100%;
  }
`

const Detail = styled.div`
  display: flex;
  position: relative;
  flex: 0 0 50%;
  flex-flow: column nowrap;

  span {
    &:first-child {
      font-size: 24px;
    }

    &:last-child {
      font-size: 12px;
    }
  }
`

const ValidityWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  justify-content: center;
  margin-right: 14px;
  transform: translateY(0);
  transition: 250ms transform ease;

  &.low {
    transform: translateY(130px);
  }

  &.medium {
    transform: translateY(0);
  }

  &.high {
    transform: translateY(-130px);
  }
`

const PriorityWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 14px;

  span {
    display: flex;
    position: relative;
    flex: 0 0 33%;
    flex-flow: column nowrap;
    justify-content: center;
    color: ${({ theme }) => theme.colors.body.text};
    transition: 250ms color ease;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -14px;
      width: 1px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.body.backgroundSubtle};
    }

    &::after {
      content: '';
      position: absolute;
      top: -9.5px;
      right: -14px;
      width: 1px;
      height: 150px;
      background-color: ${({ theme }) => theme.colors.body.backgroundSubtle};
    }

    &:first-child::after {
      top: 0;
      height: 112.5px;
    }

    &:last-child::after {
      top: auto;
      bottom: 0;
      height: 112.5px;
    }

    &.active {
      color: ${({ theme }) => theme.colors.mango.hex};
    }
  }
`

const LevelWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  margin-left: 14px;

  button {
    position: relative;
    width: 100px;
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.button.background};
    color: ${({ theme }) => theme.colors.body.text};
    text-align: center;
    box-shadow: inset 0 0 1px ${({ theme }) => theme.colors.body.background};
    transition: 250ms all ease;

    &:first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:nth-child(3),
    &:nth-child(7) {
      margin-bottom: 12px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.mango.hex};
      cursor: pointer;
    }

    &.active {
      background-color: ${({ theme }) => theme.colors.mango.hex};
      color: ${({ theme }) => theme.colors.body.backgroundAlt};
    }
  }
`
