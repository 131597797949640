import React, { createContext } from 'react'

import axios, { AxiosInstance } from 'axios'
import { config } from 'config'

import { useAuth } from './AuthProvider'

export interface IAxiosProvider {
  axiosClient: AxiosInstance
}

const AxiosContext = createContext({} as IAxiosProvider)

interface BuildingsProps {
  children?: React.ReactNode
}
const AxiosProvider: React.FunctionComponent<BuildingsProps> = ({ children }) => {
  const { getToken } = useAuth()

  const axiosInstance = axios.create({
    baseURL: config.basePath,
    responseType: 'json',
  })

  axiosInstance.interceptors.request.use(
    async (request) => {
      const token = await getToken()
      if (token) {
        request.headers['Authorization'] = `Bearer ${token}`
      }

      return request
    },
    (error) => {
      console.log(error)
      return error
    },
  )

  const contextValue = {
    axiosClient: axiosInstance,
  }
  return <AxiosContext.Provider value={contextValue}>{children}</AxiosContext.Provider>
}

const useAxios = () => {
  const context = React.useContext(AxiosContext)

  if (context === undefined) {
    throw new Error('useAxios must be used within a AxiosProvider')
  }

  return context
}

export { AxiosProvider, useAxios }
