import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Cluster } from 'types'

export const useClusterUpdate = (facilityId: string, clusterId: string) => {
  const { axiosClient } = useAxios()

  return useMutation(async (payload: Cluster) => {
    const { data } = await axiosClient.put(config.clusterUpdate(facilityId, clusterId), payload)

    return data
  })
}
