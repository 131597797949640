import React from 'react'

import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import styled from 'styled-components'

import { useEscapeKey } from 'hooks/useEscapeKey'

import { Button } from '../Button'
import { Modal } from './Modal'

interface Props {
  confirmModalState: 'open' | 'closed'
  title: string
  message: string
  onCancel: () => void
  onConfirm: () => void
  confirmText?: string
  cancelText?: string
}

export const ConfirmationModal: React.FC<Props> = ({
  confirmModalState,
  title,
  message,
  onConfirm,
  onCancel,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}) => {
  useEscapeKey(onCancel)
  return (
    <Modal state={confirmModalState}>
      <InnerModal>
        <Header>
          <h3>{title}</h3>
          <IconButton onClick={onCancel}>
            <Close style={{ color: 'white' }} />
          </IconButton>
        </Header>
        <Message>{message}</Message>
        <ButtonWrapper>
          <Button onClick={onConfirm} text={confirmText} />
          <Button onClick={onCancel} text={cancelText} />
        </ButtonWrapper>
      </InnerModal>
    </Modal>
  )
}

const InnerModal = styled.div`
  display: flex;
  margin: 50px 0;
  padding: 30px 25px;
  background-color: inherit;
  border-radius: 5px;
  flex-direction: column;
  row-gap: 25px;
  justify-content: space-between;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Message = styled.div`
  display: flex;
  max-width: 600px;
  align-items: center;
  justify-content: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  column-gap: 30px;

  button:first-child {
    background-color: ${({ theme }) => theme.colors.greyScale.medium.hex};
    &:hover {
      background-color: ${({ theme }) => theme.colors.greyScale.light.hex};
    }
  }
`
