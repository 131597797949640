import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import { Formik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import { AnimatedInput, FormFooter, FormGroup, StyledForm, SubmitButton } from 'components'
import { FacilitiesTable, PermissionsTable } from 'components/tables'
import { useFacilitiesAll, useUserCreate, useUserUpdate } from 'hooks/api/management'
import { useUsers } from 'hooks/api/users'
import { UserExtended } from 'types'

const initialUser: Partial<UserExtended> = {
  email: '',
  permissions: [],
  facilityIds: [],
}

const initialValues = (user: Partial<UserExtended>): Partial<UserExtended> => ({
  email: user?.email || '',
  permissions: user ? [...user.permissions] : [],
  facilityIds: user?.facilityIds ? [...user.facilityIds] : [],
})

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  permissions: Yup.array().min(1).required(),
})

const UserForm: React.FC = () => {
  const [user, setUser] = useState<Partial<UserExtended>>(initialUser)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { userId } = useParams()
  const { data: users } = useUsers()
  const { data: facilities } = useFacilitiesAll()

  useEffect(() => {
    if (userId && users) {
      setUser(users.find((u) => u.id === userId))
    }
  }, [users])

  const { mutate: createUser } = useUserCreate()
  const { mutate: updateUser } = useUserUpdate()

  const mutateRequest = userId ? updateUser : createUser

  const handleSubmit = async (values: UserExtended) => {
    mutateRequest(values, {
      onSuccess: () => {
        queryClient.invalidateQueries(['user'])
        navigate('/admin')
      },
    })
  }

  return (
    <Wrapper>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues(user)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ isValid, isSubmitting, handleChange }) => {
          return (
            <StyledForm>
              <h1>{userId ? t('admin.user.edit') : t('admin.user.new')}</h1>
              <FormGroup width='wide'>
                <h3>{t('admin.user.detailsTitle')}</h3>
                <AnimatedInput
                  disabled={Boolean(userId)}
                  label={t('admin.user.email')}
                  name='email'
                  handleChange={handleChange}
                />
              </FormGroup>
              <FormGroup width='wide'>
                <PermissionsTable />
              </FormGroup>
              {Boolean(facilities) && (
                <FormGroup width='wide'>
                  <FacilitiesTable facilities={facilities} />
                </FormGroup>
              )}
              <FormFooter>
                <SubmitButton
                  completed={false}
                  completedText={t('admin.user.userCreated')}
                  submitText={
                    userId ? t('admin.user.editUserSubmit') : t('admin.user.addUserSubmit')
                  }
                  loading={isSubmitting}
                  disabled={!isValid}
                />
              </FormFooter>
            </StyledForm>
          )
        }}
      </Formik>
    </Wrapper>
  )
}

export default UserForm

const Wrapper = styled.div`
  form {
    width: 100%;
    margin: 0px;
  }

  .card {
    width: 375px;
  }
`
