import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'

export const useFacilityImgCreate = (file: File) => {
  const { axiosClient } = useAxios()

  return useMutation(async (id: string) => {
    const { data } = await axiosClient.post<File>(
      config.facilityImgNew,
      { file },
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { facilityId: id },
      },
    )

    return data
  })
}
