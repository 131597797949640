import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { IconButton } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FieldArray, useFormikContext } from 'formik'
import styled from 'styled-components'

import { Button, FacilityModal, Toolbar } from 'components'
import { Facility, UserExtended } from 'types'

interface TableProps {
  facilities: Facility[]
}
export const FacilitiesTable: React.FC<TableProps> = ({ facilities }) => {
  const { values } = useFormikContext<Partial<UserExtended>>()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <>
      <TopBar>
        <h3>{t('admin.user.buildingsTitle')}</h3>
        <Toolbar>
          {Boolean(facilities.filter((f) => !values.facilityIds.includes(f.id)).length) && (
            <Button icon='add' onClick={() => setOpen(true)} />
          )}
        </Toolbar>
      </TopBar>
      <TableContainer component={Paper}>
        <Table aria-label='permissions table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '50%' }}>{t('admin.table.buildings.building')}</TableCell>
              <TableCell>{t('admin.table.buildings.address')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody sx={{ '& > tr:last-child > td': { border: 0 } }}>
            <FieldArray name='facilityIds'>
              {({ form, remove, push }) => {
                const { values } = form

                return (
                  <>
                    {values.facilityIds?.length ? (
                      <>
                        {values.facilityIds.map((id: string, index: number) => {
                          const facility = facilities.find((f) => f.id === id)
                          return (
                            <TableRow key={facility.id}>
                              <TableCell>{facility.name}</TableCell>
                              <TableCell>{`${facility.location.street} ${facility.location.buildingNumber}, ${facility.location.postCode}, ${facility.location.municipality}`}</TableCell>
                              <TableCell align='right'>
                                <IconButton
                                  aria-label='expand row'
                                  size='small'
                                  onClick={() => remove(index)}
                                >
                                  <DeleteOutlineIcon fontSize='small' />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2}>
                          {t('admin.table.buildings.assignBuildings')}
                        </TableCell>
                      </TableRow>
                    )}
                    <FacilityModal
                      facilities={facilities.filter((f) => !values.facilityIds.includes(f.id))}
                      open={open}
                      handleClose={() => setOpen(false)}
                      handleSubmit={(id) => {
                        push(id)
                        setOpen(false)
                      }}
                    />
                  </>
                )
              }}
            </FieldArray>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const TopBar = styled.div`
  display: flex;
  align-items: center;
`
