import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import i18n from 'i18n'
import styled from 'styled-components'

import { Button, ModalFooter, ModalHeader, styleModalBox, SubmitButton } from 'components'
import { Module, Permission, Role } from 'types'

type OptionType<T> = {
  value: T
  label: string
}
const roleOptions: OptionType<Role>[] = [
  { value: Role.READER, label: i18n.t('admin.user.roles.reader') },
  { value: Role.CONTRIBUTOR, label: i18n.t('admin.user.roles.contributer') },
  { value: Role.ADMIN, label: i18n.t('admin.user.roles.admin') },
]

const moduleOptions: OptionType<Module>[] = [
  { value: Module.MYUNICA, label: i18n.t('admin.user.modules.myunica') },
  { value: Module.FINANCIAL, label: i18n.t('admin.user.modules.financial') },
  { value: Module.PROPERTY, label: i18n.t('admin.user.modules.property') },
  { value: Module.SERVICE, label: i18n.t('admin.user.modules.service') },
  { value: Module.SUSTAINABILITY, label: i18n.t('admin.user.modules.sustainability') },
]

const initialPermission: Permission = {
  module: null,
  roles: [],
}

interface Props {
  permissions: Permission[]
  open: boolean
  handleClose: () => void
  handleSubmit: (permission: Permission) => void
}
export const PermissionModal: React.FunctionComponent<Props> = ({
  permissions,
  open,
  handleClose,
  handleSubmit,
}) => {
  const [permission, setPermission] = useState<Permission>(initialPermission)
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby='modal-add-permission'>
      <Box sx={styleModalBox}>
        <ModalHeader>
          <Button icon='close' onClick={handleClose} />
        </ModalHeader>
        <PermissionContainer>
          <Select
            placeholder={t('admin.user.selectModule')}
            name='role'
            options={moduleOptions.filter((o) => !permissions.find((p) => p.module === o.value))}
            onChange={(newValue) =>
              setPermission((oldValue) => ({
                ...oldValue,
                module: newValue.value,
              }))
            }
          />
          <Select
            placeholder={t('admin.user.selectRole')}
            name='role'
            options={roleOptions}
            onChange={(newValue) =>
              setPermission((oldValue) => ({
                ...oldValue,
                roles: [newValue.value],
              }))
            }
          />
        </PermissionContainer>
        <ModalFooter>
          <SubmitButton
            submitText='add permission'
            disabled={!permission.module || !permission.roles.length}
            onClick={() => {
              setPermission(initialPermission)
              handleSubmit(permission)
            }}
          />
        </ModalFooter>
      </Box>
    </Modal>
  )
}

const PermissionContainer = styled.div`
  display: flex;
  gap: 10px;

  > div {
    flex: 1;
  }
`
