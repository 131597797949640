import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import { useOutsideClick } from 'hooks'
import { Facility, Role, UserExtended } from 'types'

import { DropdownItem, DropdownMenu } from '../Dropdown'

interface TableProps {
  users: UserExtended[]
  facilities: Facility[]
}
export const UsersTable: React.FC<TableProps> = ({ users, facilities }) => {
  const { t } = useTranslation()

  return (
    <TableContainer component={Paper}>
      <Table aria-label='users table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t('admin.table.headers.email')}</TableCell>
            <TableCell align='right'>{t('admin.table.headers.role')}</TableCell>
            <TableCell align='right'>{t('admin.table.headers.status')}</TableCell>
            <TableCell align='right' />
          </TableRow>
        </TableHead>
        <TableBody sx={{ '& > tr:last-child > td': { border: 0 } }}>
          {users.map((user) => (
            <Row key={user.email} user={user} facilities={facilities} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface RowProps {
  user: UserExtended
  facilities: Facility[]
}
const Row: React.FC<RowProps> = ({ user, facilities }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [showDropdown, setShowDropdown] = React.useState(false)
  const ref = useOutsideClick(() => setShowDropdown(false))

  return (
    <React.Fragment>
      <TableRow sx={{ '& > td': { border: 0 } }}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell align='right'>
          {user.permissions.find((p) => p.roles.includes(Role.ADMIN))
            ? t('admin.table.permissions.admin')
            : t('admin.table.permissions.user')}
        </TableCell>
        <TableCell align='right'>
          <Chip label='Active' color='primary' />
        </TableCell>
        <TableCell align='right'>
          <DropdownMenu
            innerRef={ref}
            show={showDropdown}
            setShow={setShowDropdown}
            alignItem='end'
          >
            <DropdownItem
              name={t('common.edit')}
              action={() => navigate(`/admin/user/${user.id}/edit`)}
            />
            <DropdownItem
              name={t('common.delete')}
              style='danger'
              action={() => console.log('delete user')}
            />
          </DropdownMenu>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label='permissions'>
                <TableHead>
                  <TableRow>
                    <TableCell component='th' scope='row' sx={{ border: 0 }}>
                      <Typography variant='h5' gutterBottom>
                        {t('admin.table.permissions.header')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell component='th' scope='row'>
                      {t('admin.table.permissions.module')}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {t('admin.table.permissions.role')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.permissions.map((p) => (
                    <TableRow
                      key={p.module}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{p.module}</TableCell>
                      <TableCell>{p.roles.join(', ')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {Boolean(user.facilityIds?.length) && (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell component='th' scope='row' sx={{ border: 0 }}>
                          <Typography variant='h5' gutterBottom>
                            {t('admin.table.buildings.header')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {t('admin.table.buildings.building')}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {t('admin.table.buildings.address')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {user.facilityIds.map((id) => {
                        const facility = facilities.find((f) => f.id === id)
                        return (
                          <TableRow
                            key={id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell>{facility.name}</TableCell>
                            <TableCell>{`${facility.location.street} ${facility.location.buildingNumber}, ${facility.location.postCode} ${facility.location.municipality}`}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </>
                )}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
