import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Cluster } from 'types'

export const useClusterCreate = (id: string) => {
  const { axiosClient } = useAxios()

  return useMutation(async (payload: Cluster) => {
    const { data } = await axiosClient.post(config.clusters(id), payload)

    return data
  })
}
