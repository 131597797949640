import { useMutation } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'

export const usePolicyTemplateDelete = (
  policyTemplateId: string,
  options?: { onSuccess: () => void },
) => {
  const { axiosClient } = useAxios()

  return useMutation(
    async () => {
      const { data } = await axiosClient.delete(config.policyTemplateDelete(policyTemplateId))

      return data
    },
    {
      ...options,
    },
  )
}
