import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS,
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
})
appInsights.loadAppInsights()

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || []
  env.tags['ai.cloud.role'] = 'testTag'
})

export { reactPlugin, appInsights }
