import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { useAuth } from '../../../context/AuthProvider'

const WelcomeName: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { activeAccount } = useAuth()

  return (
    <Container>
      <h4>
        {activeAccount?.name
          ? t('header.welcomeName', { name: activeAccount.name.split(' ')[0] })
          : t('header.welcome')}
      </h4>
    </Container>
  )
}

export default WelcomeName

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  height: 100%;
`
