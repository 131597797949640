import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import { useAppData } from 'context'
import { usePolicy, usePolicyImg } from 'hooks/api/policies'
import { cardContainer, cardImageAvatar, cardImageWrapper } from 'styles'
import { Cluster } from 'types'

interface Props {
  className?: string
  cluster: Cluster
  image: string
  number?: number
  total?: number
}

interface DetailItem {
  className?: string
  label: string
  value: string | number
}

export const ClusterCard: React.FC<Props> = ({ className, cluster, image, number, total }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { view } = useAppData()
  const { data: policy } = usePolicy(cluster?.facilityId, cluster?.id, {
    enabled: !!cluster?.policyId,
  })
  const { data: policyImage } = usePolicyImg(policy?.policyTemplateId, { enabled: !!policy })

  const detailItems: DetailItem[] = policy
    ? [
        { label: 'discipline', value: cluster.disciplines[0] },
        { label: 'policyScore', value: (Math.random() * (10 - 6) + 6).toFixed(1) },
      ]
    : [{ label: 'needsPolicy', value: t('policies.needsPolicy'), className: 'highlight' }]

  return (
    <CardContainer
      className={`${className} ${view}`}
      onClick={() => navigate(`clusters/${cluster.id}`)}
      number={number}
      total={total}
    >
      {!!image && (
        <ImageWrapper>
          {image ? <img src={image} alt={cluster.name} /> : <Skeleton width={90} height={90} />}
          <Avatar>{policyImage ? <img src={policyImage} /> : ''}</Avatar>
        </ImageWrapper>
      )}
      <Content>
        <h3>{cluster.name}</h3>
        <DetailList>
          {detailItems &&
            detailItems
              .filter((detail) => detail.value !== '' && detail.value !== 'None')
              .map(({ className, value }, i) => (
                <DetailItem key={i} className={className}>
                  {value}
                </DetailItem>
              ))}
        </DetailList>
      </Content>
    </CardContainer>
  )
}

const CardContainer = styled.div`
  ${cardContainer}
`

const ImageWrapper = styled.div`
  ${cardImageWrapper}
`

const Avatar = styled.div`
  ${cardImageAvatar}
`

const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
`

const DetailList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  margin: 8px 0 0;
  padding: 0;
  list-style-type: none;
`

const DetailItem = styled.li`
  &.highlight {
    color: ${({ theme }) => theme.colors.body.primary};
    font-weight: 500;
  }

  &:not(:first-child) {
    position: relative;
    margin-left: 25px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 2.5px);
      left: -15px;
      width: 5px;
      height: 5px;
      background-color: ${({ theme }) => theme.colors.body.text};
      border-radius: 50%;
    }
  }
`
