import React from 'react'
import { Navigate, BrowserRouter as Router, useRoutes } from 'react-router-dom'

import { Layout } from 'components'
import { useUserData } from 'context/UserProvider'
import {
  ClusterDetail,
  ClusterForm,
  DocumentForm,
  DocumentsOverview,
  FacilitiesOverview,
  FacilityCareDetail,
  FacilityDetail,
  FacilityForm,
  Home,
  PoliciesOverview,
  PolicyDetail,
  PolicyForm,
  RequestDetail,
  RequestForm,
} from 'views'
import Admin from 'views/Admin'
import UserForm from 'views/forms/UserForm'
import Settings from 'views/Settings'
import { Module, Role } from 'types'

const Routes = () => {
  const { user } = useUserData()

  const isModuleEnabled = (module: Module) => {
    return user.permissions && user.permissions.some((p) => p.module === module)
  }

  const canEdit = (module: Module) => {
    return (
      user.permissions &&
      user.permissions
        .find((p) => p.module === module)
        .roles?.some((r) => r === Role.CONTRIBUTOR || r === Role.ADMIN)
    )
  }

  return useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [{ path: '/', element: <Home /> }],
    },
    {
      path: '/settings',
      element: <Layout tool='settings' />,
      children: [{ path: '/settings', element: <Settings /> }],
    },
    {
      path: '/admin',
      element: <Layout tool='admin' />,
      children: [
        { path: '/admin', element: <Admin /> },
        { path: '/admin/user/new', element: <UserForm /> },
        { path: '/admin/user/:userId/edit', element: <UserForm /> },
      ],
    },
    {
      path: '/map',
      element: isModuleEnabled(Module.PROPERTY) ? <Layout tool='map' /> : <Navigate to='/' />,
      children: [
        { path: '/map/facilities', element: <FacilitiesOverview /> },
        {
          path: '/map/facilities/new',
          element: canEdit(Module.PROPERTY) ? <FacilityForm /> : <Navigate to='/map/facilities' />,
        },
        { path: '/map/facilities/:facilityId', element: <FacilityDetail /> },
        {
          path: '/map/facilities/:facilityId/edit',
          element: canEdit(Module.PROPERTY) ? <FacilityForm /> : <Navigate to='/map/facilities' />,
        },
        { path: '/map/policies', element: <PoliciesOverview /> },
        { path: '/map/policies/:policyId', element: <PolicyDetail /> },
        {
          path: '/map/policies/new',
          element: canEdit(Module.PROPERTY) ? <PolicyForm /> : <Navigate to='/map/policies' />,
        },
        {
          path: '/map/policies/:policyId/edit',
          element: canEdit(Module.PROPERTY) ? <PolicyForm /> : <Navigate to='/map/policies' />,
        },
        {
          path: '/map/facilities/:facilityId/clusters/:clusterId',
          element: <ClusterDetail />,
        },
        {
          path: '/map/facilities/:facilityId/clusters/new',
          element: canEdit(Module.PROPERTY) ? <ClusterForm /> : <Navigate to='/map/facilities' />,
        },
        {
          path: '/map/facilities/:facilityId/clusters/:clusterId/edit',
          element: canEdit(Module.PROPERTY) ? <ClusterForm /> : <Navigate to='/map/facilities' />,
        },
      ],
    },
    {
      path: '/fix',
      element: isModuleEnabled(Module.SERVICE) ? <Layout tool='fix' /> : <Navigate to='/' />,
      children: [
        { path: '/fix/facilities', element: <FacilitiesOverview /> },
        { path: '/fix/facilities/:facilityId', element: <FacilityDetail /> },
        { path: '/fix/facilities/:facilityId/requests/new', element: <RequestForm /> },
        { path: '/fix/facilities/:facilityId/requests/:requestId', element: <RequestDetail /> },
        { path: '/fix/facilities/:facilityId/requests/:requestId/edit', element: <RequestForm /> },
      ],
    },
    {
      path: '/docs',
      element: isModuleEnabled(Module.MYUNICA) ? <Layout tool='docs' /> : <Navigate to='/' />,
      children: [
        { path: '/docs/facilities', element: <FacilitiesOverview /> },
        { path: '/docs/facilities/:facilityId', element: <DocumentsOverview /> },
        { path: '/docs/facilities/:facilityId/documents/new', element: <DocumentForm /> },
        {
          path: '/docs/facilities/:facilityId/documents/:documentId/edit',
          element: <DocumentForm />,
        },
      ],
    },
    {
      path: '/costs',
      element: isModuleEnabled(Module.FINANCIAL) ? <Layout tool='costs' /> : <Navigate to='/' />,
      children: [
        { path: '/costs/facilities', element: <FacilitiesOverview /> },
        { path: '/costs/facilities/:facilityId', element: <FacilityDetail /> },
      ],
    },
    {
      path: '/care',
      element: isModuleEnabled(Module.SUSTAINABILITY) ? (
        <Layout tool='care' />
      ) : (
        <Navigate to='/' />
      ),
      children: [
        { path: '/care/facilities', element: <FacilitiesOverview /> },
        { path: '/care/facilities/:facilityId', element: <FacilityCareDetail /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to='/' replace />,
    },
  ])
}

export const AuthenticatedRoutes = () => {
  return (
    <Router>
      <Routes />
    </Router>
  )
}
