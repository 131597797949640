import React, { createContext } from 'react'

import { useUser } from 'hooks/api/users'
import { User } from 'types'

export interface IUserProvider {
  user: User
}

const UserContext = createContext({} as IUserProvider)

interface Props {
  children?: React.ReactNode
}
const UserProvider: React.FunctionComponent<Props> = ({ children }) => {
  const { data: user } = useUser()

  const contextValue = {
    user,
  }
  return <UserContext.Provider value={contextValue}>{user && children}</UserContext.Provider>
}

const useUserData = () => {
  const context = React.useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUserData must be used within a UserProvider')
  }

  return context
}

export { UserProvider, useUserData }
