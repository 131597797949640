import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import formatcoords from 'formatcoords'
import mapboxgl from 'mapbox-gl'
import styled from 'styled-components'

import { useFacility } from 'hooks/api/facilities'

interface Props {
  facilityId: string
}

// weird mapbox stuff
// eslint-disable-next-line
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('mapbox-gl/dist/mapbox-gl-csp-worker').default
mapboxgl.accessToken =
  'pk.eyJ1IjoiZGlnaXRhbGluZGl2aWR1YWwiLCJhIjoiY2w5bHliZTlzMWt5ZjN1cjc2MThlM2V5dSJ9.PVdFSZqs9FtL-Xxm9KChRA'

export const FacilityDetailsTab: React.FC<Props> = ({ facilityId }) => {
  const { t } = useTranslation()
  const { data: facility } = useFacility(facilityId)
  let coords: string[] | boolean = false

  if (facility?.location.latitude && facility?.location.longitude) {
    coords = formatcoords(facility.location.latitude, facility?.location.longitude)
      .format('DD MM ss X', {
        latLonSeparator: ',',
      })
      .split(',')
  }

  useEffect(() => {
    if (!coords) return

    const div = document.getElementById('map')
    const children = Array.prototype.slice.call(div?.children || [])

    children.forEach((child: HTMLElement) => {
      div.removeChild(child)
    })

    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [facility?.location.longitude, facility?.location.latitude],
      zoom: 12,
      projection: { name: 'globe' },
    })

    new mapboxgl.Marker()
      .setLngLat([facility?.location.longitude, facility?.location.latitude])
      .addTo(map)
  }, [coords])

  return (
    <Container>
      <DetailsWrapper>
        <h3>{t('facilities.details.space')}</h3>
        <DetailWrapper>
          <DetailColumn>
            <span>{facility?.details.grossArea} m2</span>
            <span>{t('facilities.details.grossArea')}</span>
            <span>{facility?.details.usefulArea} m2</span>
            <span>{t('facilities.details.usefulArea')}</span>
          </DetailColumn>
          <DetailColumn>
            <span>{facility?.details.rentArea} m2</span>
            <span>{t('facilities.details.rentableArea')}</span>
            <span>{facility?.details.formFactor}</span>
            <span>{t('facilities.details.formFactor')}</span>
          </DetailColumn>
        </DetailWrapper>
        <h3>{t('facilities.details.location')}</h3>
        <LocationDetailWrapper>
          <LocationImage id='map' />
          <DetailColumn>
            <LocationDetailsWrapper>
              <span>
                {facility?.location.street} {facility?.location.buildingNumber}{' '}
                {facility?.location.extension}
              </span>
              <span>
                {facility?.location.postCode} {facility?.location.city}
              </span>
              <span>{facility?.location.province}</span>
              <span>{coords ? coords[0] : ''}</span>
              <span>{coords ? coords[1] : ''}</span>
              <span>{facility?.location.municipality}</span>
            </LocationDetailsWrapper>
          </DetailColumn>
        </LocationDetailWrapper>
      </DetailsWrapper>
      <DetailsWrapper>
        <h3>{t('facilities.details.energyLabelHeader')}</h3>
        <DetailWrapper>
          <DetailColumn>
            <span id='energyLabel'>{facility?.energyLabel}</span>
            <span>{t('facilities.details.energyLabel')}</span>
            <span>{'< 180kWh/m2'}</span>
            <span>{t('facilities.details.fossilConsumption')}</span>
          </DetailColumn>
          <DetailColumn>
            <span>{facility?.details.year} m2</span>
            <span>{t('facilities.details.constructionYear')}</span>
            <span>#8 </span>
            <span>{t('facilities.details.efficientAmong2004')}</span>
          </DetailColumn>
        </DetailWrapper>
        <h3>{t('facilities.details.purpose')}</h3>
        <DetailWrapper>
          <div>{facility?.description}</div>
        </DetailWrapper>
      </DetailsWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const DetailsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-basis: 100%;
  flex: 2;
`

const DetailWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 50px;
  gap: 5px;

  span:nth-child(2n) {
    font-size: 10px;
    margin-bottom: 15px;
  }
  span[id='energyLabel'] {
    color: ${({ theme }) => theme.colors.jungle.hex};
  }
`

const DetailColumn = styled.div`
  display: flex;
  flex-flow: column;
  gap: 5px;
  width: 50%;
`

const LocationDetailWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 50px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;

  span:nth-child(2n + 1) {
    margin-bottom: 10px;
  }
`

const LocationDetailsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 5px;
`

const LocationImage = styled.div`
  width: 190px;
  height: 175px;
`
