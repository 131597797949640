import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Policy } from 'types'

export const usePolicy = (
  facilityId: string,
  clusterId: string,
  options?: { enabled: boolean },
) => {
  const { axiosClient } = useAxios()

  return useQuery(
    ['policy', clusterId],
    async () => {
      const { data } = await axiosClient.get<Policy>(config.policy(facilityId, clusterId))

      return data
    },
    {
      ...options,
    },
  )
}
