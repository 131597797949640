import { useMutation, useQueryClient } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'

export const useContactDelete = (contactId: string, facilityId: string) => {
  const { axiosClient } = useAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      const { data } = await axiosClient.delete(config.contactsDelete(contactId))

      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contacts', facilityId])
      },
    },
  )
}
