import React from 'react'

import { LinearProgress } from '@mui/material'

import { Container } from 'components'

const InitialLoading: React.FunctionComponent = () => {
  return (
    <Container>
      <LinearProgress />
    </Container>
  )
}

export default InitialLoading
