import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import styled from 'styled-components'

import { BreadcrumbNav, Button, ConfirmationModal, DropdownItem, DropdownMenu } from 'components'
import { useOutsideClick } from 'hooks'
import { useFacility, useFacilityImg } from 'hooks/api/facilities'
import {
  useRequestDelete,
  useRequestFiles,
  useRequests,
  useRequestUpdate,
} from 'hooks/api/requests'
import { RequestFile } from 'types'

export const RequestDetail: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { requestId, facilityId } = useParams()
  const [files, setFiles] = useState<RequestFile[]>([])
  const [showDropdown, setShowDropdown] = useState(false)
  const [confirmModalState, setConfirmModalState] = useState<'open' | 'closed'>('closed')

  const ref = useOutsideClick(() => setShowDropdown(false))

  const { data: facility } = useFacility(facilityId)
  const { data: requests } = useRequests(facility.id)

  const request = requests?.find((request) => request.id === requestId)
  const contactIds = request?.contacts?.flatMap((contact) => contact.id) ?? []

  const { data: facilityImage } = useFacilityImg(facilityId)

  const parseFileName = (fileName: string) => {
    const re = /(?:\.([^.]+))?$/
    const ext = re.exec(fileName)[1]
    const name = fileName?.replace(ext, '') || ''

    return { ext, name }
  }

  const results = useRequestFiles(facilityId, requestId, request?.files || [])

  const isLoading = useCallback(() => results.some((result) => result.isLoading), [results])

  if (!isLoading()) {
    results.map((result, i) => {
      const newFile = new File(
        [result.data.data],
        request?.files?.[i].uploadedFileName,
      ) as RequestFile
      newFile.fileName = request?.files?.[i].fileName

      const exists = files.find((file) => file.fileName === newFile.fileName)
      if (!exists) setFiles((files) => [...files, newFile])
    })
  }

  const { mutate: updateRequest } = useRequestUpdate(facilityId, requestId)

  const { mutate: deleteRequest } = useRequestDelete(facilityId, requestId)

  const user = {
    firstName: 'Jan',
    lastName: 'Dekker',
  }

  const date1 = new Date(request?.createdUtc)
  const date2 = new Date()
  const timeDifference = date2.getTime() - date1.getTime()
  const dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24))
  const numDaysAgo =
    dayDifference === 1
      ? `${dayDifference} ${t('requests.dayAgo')}`
      : `${dayDifference} ${t('requests.daysAgo')}`

  return (
    <>
      <BreadcrumbNav
        crumbs={[
          {
            title: facility?.name,
            image: facilityImage,
            link: `/fix/facilities/${facilityId}`,
          },
        ]}
        size='small'
      />
      <RequestsOverview>
        {requests?.length &&
          requests.map((request) => (
            <RequestCard
              key={request.id}
              className={request.id === requestId ? 'active' : ''}
              onClick={() => navigate(`/fix/facilities/${facilityId}/requests/${request.id}`)}
            >
              <RequestItem>{request.type}</RequestItem>
              <RequestItem> {facility?.name}</RequestItem>
              <RequestItem>
                {new Date(request?.createdUtc).toLocaleString('en', {
                  day: '2-digit',
                  month: 'short',
                })}
              </RequestItem>
            </RequestCard>
          ))}
      </RequestsOverview>
      <FullWidthContainer>
        <Content>
          <RequestDetailWrapper>
            <h3>{request?.type}</h3>
            <DropdownMenu innerRef={ref} show={showDropdown} setShow={setShowDropdown}>
              <DropdownItem
                name='edit'
                action={() => navigate(`/fix/facilities/${facilityId}/requests/${requestId}/edit`)}
              />
              <DropdownItem name='delete' style='danger' action={() => deleteRequest()} />
            </DropdownMenu>
          </RequestDetailWrapper>
          <ColumnWrapper>
            <Column>
              <InfoWrapper>
                <CalendarMonthIcon />
                <Text>
                  {new Date(request?.createdUtc).toLocaleString('en', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  })}
                  {dayDifference < 8 && ` (${numDaysAgo})`}
                </Text>
              </InfoWrapper>
              <InfoWrapper>
                <LocationOnIcon />
                <TextWrapper>
                  <span>{facility?.name}</span>
                  <Text>{request?.locationDescription}</Text>
                </TextWrapper>
              </InfoWrapper>
              <InfoWrapper>
                <MailOutlineIcon />
                {request?.contacts?.length > 0 ? (
                  <Text>
                    <a href={`mailto:${request?.contacts[0]?.email}`}>
                      {request?.contacts[0]?.firstName + ' ' + request?.contacts[0]?.lastName}
                    </a>
                  </Text>
                ) : (
                  <Text>{t('requests.noContact')}</Text>
                )}
              </InfoWrapper>
              <InfoWrapper>
                <PersonOutlineIcon />
                <Text>{`${t('requests.reportedBy')} ${user.firstName} ${user.lastName}`}</Text>
              </InfoWrapper>
            </Column>
            <Column>
              <DetailsInfo>{request?.details}</DetailsInfo>
              <FilePreviewWrapper>
                {files?.length > 0 &&
                  files.map((file, i) => {
                    const { name, ext } = parseFileName(file.name)

                    let preview = null
                    if (file.type.split('/').includes('image')) {
                      preview = URL.createObjectURL(file)
                    }

                    return (
                      <FilePreview key={i} href={URL.createObjectURL(file)} download={file.name}>
                        {preview && (
                          <>
                            <img src={preview} alt={name} />
                            <span className='mask' />
                          </>
                        )}
                        <span className='name'>{name}</span>
                        <span className='ext'>.{ext}</span>
                      </FilePreview>
                    )
                  })}
              </FilePreviewWrapper>
            </Column>
          </ColumnWrapper>
          <FormFooter>
            {request?.status !== 'Resolved' ? (
              <Button onClick={() => setConfirmModalState('open')} text='Mark as resolved' />
            ) : (
              <Button onClick={() => setConfirmModalState('open')} text='Mark as unresolved' />
            )}
          </FormFooter>
        </Content>
      </FullWidthContainer>
      <ConfirmationModal
        title={request?.status === 'None' ? 'Was this issue addressed?' : 'Unresolve this issue?'}
        message={`You’re about to ${request?.status === 'None' ? 'resolve' : 'unresolve'} a ${
          request?.type
        } in ${facility?.name} reported on ${new Date(request?.createdUtc).toLocaleString('en', {
          day: '2-digit',
          month: 'short',
        })} by John Doe. The reporter and the service provider will be notified by email.`}
        confirmModalState={confirmModalState}
        onCancel={() => setConfirmModalState('closed')}
        onConfirm={() => {
          updateRequest({
            ...request,
            contactIds,
            status: request?.status === 'None' ? 'Resolved' : 'None',
          })
          setConfirmModalState('closed')
        }}
        confirmText={request?.status === 'None' ? 'Yes, it’s resolved' : 'Yes, unresolve'}
        cancelText={
          request?.status === 'None' ? 'No, still needs attention' : 'No, it’s still resolved'
        }
      />
    </>
  )
}

const FormFooter = styled.div`
  position: relative;
  margin-top: 50px;
  padding: 30px 0 0 0;

  button {
    width: 200px;
    margin-right: 15px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -50vw;
    width: 100vw;
    height: 1px;
    margin-left: 50%;
    backdrop-filter: blur(3px);
    background-color: ${({ theme }) => theme.colors.body.background};
  }
`
const FilePreviewWrapper = styled.div`
  display: flex;
  flex-wrap: row wrap;
`

const FilePreview = styled.a`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 90px;
  height: 90px;
  margin: 0 15px -15px 0;
  padding: 6px;
  background-color: ${({ theme }) => theme.colors.filePreview.background};
  color: ${({ theme }) => theme.colors.filePreview.text};
  font-weight: 300;
  border-radius: 4px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    z-index: 5;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.filePreview.background};
    border-radius: 4px;
    opacity: 0.5;
    z-index: 7;
  }

  .remove {
    display: block;
    position: absolute;
    top: 6px;
    right: 6px;
    width: 18px;
    height: 18px;
    background-color: ${({ theme }) => theme.colors.filePreview.button};
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;

    &::after {
      content: '\u00d7';
      position: absolute;
      top: -1.75px;
      left: 3.75px;
    }
  }

  .name {
    display: -webkit-box;
    margin-bottom: 4px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    z-index: 10;
  }

  .ext {
    font-weight: 500;
    font-stretch: condensed;
    text-transform: uppercase;
    z-index: 10;
  }
`
const FullWidthContainer = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  background-color: ${({ theme }) => theme.colors.body.backgroundAlt};
`
const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 60px 60px;
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  width: 50%;
  flex-flow: column;
  padding: 25px 0;
`

const InfoWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-right: 50px;

  p,
  span {
    margin-left: 20px;
  }

  span {
    color: ${({ theme }) => theme.colors.body.primary};
  }
`
const DetailsInfo = styled.div`
  margin-bottom: 20px;
`
const Text = styled.p`
  margin-top: 3px;
  font-weight: 300;
`
const RequestsOverview = styled.div`
  display: flex;
  position: relative;
  padding: 15px 10px 15px 0;
  flex-flow: row nowrap;
  column-gap: 15px;
  overflow-x: scroll;
  padding-left: 25px;
`
const RequestDetailWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  gap: 5px 50px;
  justify-content: flex-start;
  align-items: center;
`
const TextWrapper = styled.div`
  display: flex;
  flex-flow: column;
`
const RequestCard = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  padding: 15px 20px;
  background: ${({ theme }) => theme.colors.card.backgroundAlt};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.button.text};
  transform: scale(1) translateY(0);
  transition: box-shadow 300ms ease, transform 300ms ease;
  list-style-type: none;

  &.active {
    background: ${({ theme }) => theme.colors.body.primary};
    color: ${({ theme }) => theme.colors.button.textActive};
  }

  &:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.03) translateY(-5px);
    cursor: pointer;
  }
`
const RequestItem = styled.li`
  width: max-content;
  &:not(:last-child) {
    position: relative;
    margin-right: 25px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 2.5px);
      right: -15px;
      width: 5px;
      height: 5px;
      background-color: ${({ theme }) => theme.colors.body.text};
      border-radius: 50%;
    }
  }

  &:last-child {
    min-width: 70px;
  }
`
