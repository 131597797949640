import React from 'react'
import { useTranslation } from 'react-i18next'

import { Formik, FormikHelpers } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import { AnimatedInput, Button, FormGroup, StyledForm, SubmitButton } from 'components'
import { useContactCreate, useContactUpdate } from 'hooks/api/contacts'
import { Contact } from 'types'

interface Props {
  contact?: Contact
  facilityId: string
  closeForm: () => void
}

const ContactSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string(),
  title: Yup.string(),
  email: Yup.string().email().required(),
  phone: Yup.string(),
})

const initialValues = (contact: Contact): Contact => ({
  firstName: contact?.firstName || '',
  lastName: contact?.lastName || '',
  title: contact?.title || '',
  email: contact?.email || '',
  phone: contact?.phone || '',
})

export const ContactPersonForm: React.FC<Props> = ({ contact, facilityId, closeForm }) => {
  const { t } = useTranslation()

  const { mutate: createContact } = useContactCreate(facilityId)
  const { mutate: updateContact } = useContactUpdate(facilityId)

  const handleSubmit = async (values: Contact, { resetForm }: FormikHelpers<Contact>) => {
    values.phone === '' && delete values.phone

    contact ? updateContact({ ...contact, ...values }) : createContact(values)
    resetForm()
    closeForm()
  }

  return (
    <Formik
      initialValues={initialValues(contact)}
      validationSchema={ContactSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, handleChange }) => {
        return (
          <StyledForm>
            <StyledHeading>
              {contact ? t('common.edit') : t('common.addNew')} {t('contacts.contact')}{' '}
              <Button type='button' icon='close' onClick={closeForm} />
            </StyledHeading>
            <FormGroup>
              <AnimatedInput
                label={t('contacts.firstName')}
                name='firstName'
                handleChange={handleChange}
              />
              <AnimatedInput
                label={t('contacts.lastName')}
                name='lastName'
                handleChange={handleChange}
              />
              <AnimatedInput label={t('contacts.title')} name='title' handleChange={handleChange} />
              <AnimatedInput label={t('contacts.email')} name='email' handleChange={handleChange} />
              <AnimatedInput label={t('contacts.phone')} name='phone' handleChange={handleChange} />
              <SubmitButton
                completedText={t('contacts.added')}
                submitText={t('common.save')}
                loading={isSubmitting}
                disabled={!isValid}
                type='primary'
              />
            </FormGroup>
          </StyledForm>
        )
      }}
    </Formik>
  )
}

const StyledHeading = styled.h3`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0;
`
