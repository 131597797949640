import { useQuery } from '@tanstack/react-query'
import { config } from 'config'

import { useAxios } from 'context/AxiosProvider'
import { Document } from 'types'

export const useDocuments = (facilityId: string) => {
  const { axiosClient } = useAxios()

  return useQuery(['documents', facilityId], async () => {
    const { data } = await axiosClient.get<Document[]>(config.documents(facilityId))

    return data
  })
}
